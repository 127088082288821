.PlayerContainer
  .play-pause-m-container,.play-p-icon
    height: 40px
    width: 40px
    display: block
    cursor: pointer
    border-radius: 100%

.PlayerContainer,.Single-player
  position: fixed
  bottom: -200px
  width: 100%
  z-index: 1000
  left: 0
  transition: all .8s
  .filtered-dropdown
    .dropdown-menu.show
      margin-left: -15px !important
  .rhap_container
    background-color: #202530
    @media screen and (max-width: 500px)
      padding: 10px 0
  .rhap_progress-section
    flex-wrap: wrap
    flex: 0 1 100%
  .rhap_progress-container
    flex: 1 1 auto
    justify-content: center
  .rhap_total-time,.rhap_current-left-time
    color: #ffffff
    font-size: 12px
    flex: 0 1 auto
    margin: 0 10px
  .player-icon-container
    color: #8e96ab
    margin: 0 9px
    font-size: 12px
  .track-info-container
    flex: 0 1 15%
    border-right: 1px solid #e4ebf2
    padding: 0 15px
  .controls-container
    flex: 0 1 10%
    display: flex
    align-items: center
    justify-content: center
  .standard-col
    justify-content: center
    display: flex
    flex: 0 1 8%
    margin: 0 15px
    .icon-container
      margin: 0 5px
      .buy-mask-container,.favorite-mask-container,
      .favorite-icon,.buy-icon
        height: 31px
        width: 31px
  .rhap_volume-controls
    flex: 0 1 7% !important
    justify-content: initial !important
  .song-m-container,.song-icon,
  .track-m-container,.track-icon
    height: 30px
    width: 30px
    display: block
    margin-left: auto
    cursor: pointer
    margin-right: auto
    border-radius: 100%
  .play-p-icon
    mask: url("../../../icons/play_fill.svg") no-repeat center
    background-color: #ffffff
  .play-p-icon.paused
    mask: url("../../../icons/play-circle-solid.svg") no-repeat center
    background-color: #ffffff
    mask-size: 20px
  .play-pause-m-container
    background-color: #fc6432
    margin: 0 15px
  .rollback-m-container,.rollback-icon
    height: 14px
    width: 14px
    display: block
    cursor: pointer


  .rollback-icon
    mask: url("../../../icons/rolback.svg") no-repeat center
    background-color: #fc6432
  .reversed
    .rollback-icon
      transform: rotate(180deg)
  .song-icon
    mask: url("../../../icons/play_song.svg") no-repeat center
    background-color: #0765e3
  .song-m-container
    background-color: #ffffff
  .track-icon
    mask: url("../../../icons/play_track.svg") no-repeat center
    background-color: #0765e3
  .track-m-container
    background-color: #ffffff
  .track-info-container a
    color: #ffffff
    font-weight: bolder
    font-size: 16px
  .track-info-container span
    font-family: Lato, sans-serif
    font-size: 14px
    color: #7b8693
  .song-m-container,.track-m-container,
  .favorite-mask-container
    margin-bottom: 5px
  .buy-mask-container
    margin-bottom: 2.5px
  .rhap_controls-section
    justify-content: initial !important
    flex: initial
  .rhap_volume-button
    color: #ffffff !important
  .rhap_volume-bar
    background-color: #0099e8 !important
  .rhap_volume-indicator
    border: none !important
    border-radius: 0
    width: 9px
    height: 6px
    margin-left: -6px
    top: -1px
  .current-type-play
    background-color: #0765e3
    div
      background-color: #ffffff
@media screen and (max-width: 1190px)
  .PlayerContainer
    .rhap_container
      @media screen and (max-width: 825px)
        padding: 10px 0
        border-top-left-radius: 30px
        border-top-right-radius: 30px
    .rhap_progress-container
      @media screen and (max-width: 825px)
        order: 5
    .track-info-container
      flex: 0 1 13%
      border-right: 1px solid #e4ebf2
      padding: 0 15px
      @media screen and (max-width: 825px)
        flex: 0 1 100%
        order: 0
        border: none
    .controls-container
      flex: 0 1 10%
      justify-content: center
      @media screen and (max-width: 825px)
        flex: 0 1 29.8%
        order: 2
        .play-pause-m-container
          margin: 0 10px
    @media screen and (max-width: 825px)
      .rhap_total-time
        order: 4
      .rhap_current-left-time
        order: 6
      .first-order
          order: 1
      .second-order
          order: 3
    .standard-col
      flex: 0 1 10%
      justify-content: center
      margin: 0
      @media screen and (max-width: 825px)
        flex: 0 1 33%
        border: none
        height: auto
    .rhap_volume-controls
      flex: 0 1 10% !important
      justify-content: center !important
      @media screen and (max-width: 825px)
        order: 7
        flex: 0 1 10% !important
      @media screen and (max-width: 500px)
        flex: 0 1 30% !important
