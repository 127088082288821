.content-partner-page
  font-family: Lato
  .hero-content-container
    height: calc(100% - 78px) !important
    display: flex
  .container
    max-width: 1300px
    margin-top: auto !important
    margin-bottom: auto
  .hero-parallax2
    background-image: url(../../../images/ContentPartner/content_provider.jpg)  !important
    height: 700px
    max-height: initial
    text-transform: none
    @media screen and (max-width: 800px)
      height: auto
    .header-text-container
      width: 60%
      @media screen and (max-width: 1000px)
        width: 80%
      @media screen and (max-width: 800px)
        width: 85%
        margin-left: 10%
        text-align: center
      .header-title-child
        font-size: 52px
        font-weight: bold
        text-align: left
        color: #ffffff
        @media screen and (max-width: 800px)
          font-size: 49px
          line-height: 1.2
          div
            margin: 20px 0
        span
          color: #ff5529
      .header-subtitle-child
        margin-top: 21px
        font-family: Roboto
        font-weight: 300
        font-size: 38px
        color: #92a5c3
        text-align: left
        @media screen and (max-width: 800px)
          font-size: 30px
  .sec-title
    font-weight: bold
    text-align: center
    font-size: 30px
    padding-bottom: 100px
    padding-top: 40px
  .subtitle-section
    background-color: #f2f5fa
    position: relative
    margin-bottom: 70px
    .container
      padding: 50px 0
      color: #92a5c3
      font-size: 32px
      font-weight: bold
      font-family: Lato
      text-align: center
      @media screen and (max-width: 800px)
        font-size: 24px
  .waiting-section
    padding: 80px 0
    background-image: radial-gradient(circle at 68% 40%, #0b2233, #102943 47%)
    position: relative
    .num-text-container
      width: 100%
      &:nth-child(2)
        border-left: 2px solid #ff5529
        .num-text
          float: right
    .black-section
      background-color: #111111
      position: absolute
      height: 761px
      width: 318px
      left: 0
      z-index: 0
      bottom: 0
      @media screen and (max-width: 992px)
        width: 30%
      @media screen and (max-width: 500px)
        height: 400px
    .container
      .col-lg-6
        &:nth-child(1)
          padding: 80px 15px
        &:nth-child(2)
          display: flex
          flex-wrap: wrap
      .smallOrangebtn
        height: 52px
        font-weight: bold
        text-transform: uppercase
        font-size: 16px
        margin-bottom: 61px !important
    .num-text
      width: 340px
      height: 140px
      display: flex
      flex-wrap: wrap
      justify-content: center
      @media screen and (max-width: 420px)
        width: 100%
        height: auto
      .number-container
        height: 72px
        width: 72px
        color: rgba(255, 255, 255, 0.55)
        border-radius: 100%
        border: 1px solid rgba(255, 255, 255, 0.55)
        text-align: center
        font-size: 21px
        font-weight: bold
        padding: 18px
      .text-container
        font-size: 24px
        text-align: center
        color: #ffffff
        span
          font-weight: bold
    .image-container
      height: 700px
      width: 100%
      background-image: url("../../../images/ContentPartner/guitar-player.jpg")
      background-size: cover
      background-position: center
      z-index: 2
      position: inherit
      @media screen and (max-width: 992px)
        width: 100%
      @media screen and (max-width: 500px)
        height: 300px

    .waiting-title
      padding-top: 80px
      font-size: 30px
      font-weight: bold
      color: #ffffff
      text-align: center
      margin-bottom: 20px
    .waiting-sub-title
      font-size: 20px
      color: #ffffff
      text-align: center
      position: inherit
      z-index: 2
  .cont-part-section
    position: relative
    padding-bottom: 156px
    .sec-title
      padding-top: 70px
    .requirements-container
      min-height: 337px
      width: 100%
      background-image: url("../../../images/content_partner_program.jpg")
      background-size: cover
      border-radius: 22px
      .col-container
        width: 268px
      .white-line
        background-color: #d8d8d8
        height: 2px
      .new-price
        position: absolute
        color: #ffffff
        font-size: 24px
        right: 60px
        top: -6px
      .discounted-price
        padding-top: 20px
        position: relative
        .price-container
          text-decoration: line-through 2px red
      .price-container
        color: #ffffff
        font-size: 28px
      .sub-price-container
        font-size: 14px
        color: #ffffff
      .orange-dot,.dot-placer
        height: 12px
        width: 12px
        border-radius: 100%
      .orange-dot
        background-color: #ff5529
      .discounted-month
        padding-bottom: 10px
        .discounted-number
          text-decoration: line-through 2px red
          position: relative
          span
            position: absolute
            text-decoration: none
            top: 15px
            left: 5px
      .req-subtitle
        font-size: 18px
        font-weight: bold
        color: #ffffff
        &.green
          color: #8bffa5
      .req-title
        font-size: 24px
        color: #ffffff
        font-weight: bold
  .what-get-section
    position: relative
    background-color: rgb(239, 243, 248)
    padding-bottom: 110px
    .sec-title
      padding-top: 90px
    .get-cards-container
      display: flex
      min-height: 378px
      width: auto
      flex-wrap: wrap
      justify-content: center
      @media screen and (max-width: 992px)
        padding: 0 20px
      .card-item
        flex: 0 1 20%
        background-size: cover
        background-position: center
        padding: 50px 10px
        @media screen and (max-width: 992px)
          flex: 0 1 50%
        @media screen and (max-width: 700px)
          flex: 0 1 100%
        &:nth-child(1)
          background-image: url("../../../images/card_background1.jpg")
          @media screen and (max-width: 700px)
            border-top-left-radius: 20px
            border-top-right-radius: 20px
        &:nth-child(2)
          background-image: url("../../../images/card_background2.jpg")
        &:nth-child(3)
          background-image: url("../../../images/card_background3.jpg")
        &:nth-child(4)
          background-image: url("../../../images/card_background4.jpg")
        &:nth-child(5)
          background-image: url("../../../images/card_background5.jpg")
          @media screen and (max-width: 700px)
            border-bottom-left-radius: 20px
            border-bottom-right-radius: 20px
          .percent-borders
            border: 1px solid rgba(253, 54, 0, 0.3) !important
            .percent-container
              box-shadow: 0 0 25px 5px #fd3600 !important
              text-shadow: 0 1px 2px #fd3600 !important
              color: #fd3600 !important
        .card-text
          margin-top: 35px
          padding: 0 35px
          text-align: center
          color: #ffffff
          span
            font-weight: bold
        .percent-borders
          height: 93px
          width: 93px
          border-radius: 30%
          border: 1px solid rgba(6, 118, 237, 0.3)
          margin: 0 auto 0 auto
          .percent-container
            background-color: #e4f0fc
            height: 100%
            width: 100%
            border-radius: 100%
            box-shadow: 0 0 25px 5px #0676ed !important
            text-align: center
            font-size: 21px
            font-weight: 900
            text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2)
            color: #0097fc
            padding: 28px 0
  .have-songs-section
    position: relative
    .text-container
      font-size: 28px
      font-weight: 500
      color: #333333
      line-height: 42px
      margin-top: 80px
      .text-data
        margin-bottom: 60px
        padding-left: 20px
        &:nth-child(1)
          border-left: 2px solid #ff5529
        span
          color: #ff5529
    .images-sec
      position: relative
      margin: 40px 0
      .image-sec-container
        position: relative
        min-height: 478px
        width: 100%
        @media screen and (max-width: 500px)
          min-height: 300px
        .singers-studio
          position: absolute
          top: 65px
          left: 65px
          height: 346px
          width: 80%
          background-image: url("../../../images/ContentPartner/piano-player.jpg")
          background-size: cover
          background-position: center
          z-index: 3
          @media screen and (max-width: 500px)
            left: 20px
            top: 20px
            height: 240px
            width: 90%
        .yellow-div-left
          position: absolute
          left: 0
          height: 478px
          width: 162px
          background-color: #ff5529
          z-index: 1
          @media screen and (max-width: 500px)
            height: 300px
        .yellow-div-right
          position: absolute
          background-color: #ff5529
          right: 5%
          bottom: 44px
          height: 68px
          width: 56px
          z-index: 1
          @media screen and (max-width: 500px)
            z-index: 3
            right: 0
            bottom: 20px

