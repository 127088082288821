.songs-page, .faq-page, .legal-page, .contact-us-page, .license-agreement-page, .our-team-page, .profile-page , .shopping-page-content,.cart-container,.how-it-work-artist, .applicant-page, .trackowner
  .hero-parallax2
    background: url(../../../images/Bg.png) no-repeat center top
    background-size: cover

.faq-page, .legal-page , .license-agreement-page, .provider-how-it-works, .our-team-page, .applicant-page
  .oval-div
    background-color: white
.def-link
  color: #8b8b8b !important
.trackowner
 .oval-div
    background-color: #ff6633
    margin-top: 68px !important

.may-like
  font-family: Lato, sans-serif
  font-weight: bold
  margin: 50px 0
  font-size: 26px
  text-align: center

.Single-player
  position: relative
  bottom: 0
  .play-pause-m-container,.play-p-icon,
  .play-pause-layer-1,.play-pause-layer-2,.play-pause-layer-3
    height: 30px
    width: 30px
    display: flex
    cursor: pointer
    margin: auto
    border-radius: 100%
  .play-pause-layer-1
    height: 45px
    width: 45px
    background-color: rgba(252, 100, 50,0.7)
  .play-pause-layer-2
    height: 65px
    width: 65px
    background-color: rgba(252, 100, 50,0.5)
  .play-pause-layer-3
    height: 85px
    width: 85px
    background-color: rgba(252, 100, 50,0.3)
  .rhap_progress-container
    flex: 1 1 auto
    justify-content: center
  .player-icon-container
    color: #8e96ab
    margin: 0 9px
    font-size: 12px
  .controls-container
    flex: 0 1 10%
    display: flex
    align-items: center
    justify-content: center
  .standard-col
    display: flex
    flex: 0 1 8%
    margin: 0 15px
    .icon-container
      margin: 0 5px
      .buy-mask-container,.favorite-mask-container,
      .favorite-icon,.buy-icon
        height: 46px
        width: 46px
        @media screen and (max-width: 500px)
          height: 31px
          width: 31px
  .song-m-container,.song-icon,
  .track-m-container,.track-icon
    height: 46px
    width: 46px
    @media screen and (max-width: 500px)
      height: 31px
      width: 31px
  .rollback-m-container,.rollback-icon
    height: 20px
    width: 20px
    @media screen and (max-width: 500px)
      height: 15px
      width: 15px

  .song-m-container,.track-m-container,
  .buy-mask-container,.favorite-mask-container
    margin-bottom: 8px
  @media screen and (max-width: 1190px)
    .rhap_progress-container
      @media screen and (max-width: 825px)
        order: 5
    .track-info-container
      flex: 0 1 13%
      border-right: 1px solid #e4ebf2
      padding: 0 15px
      @media screen and (max-width: 825px)
        flex: 0 1 100%
        order: 0
        border: none
    .controls-container
      flex: 0 1 10%
      justify-content: center
      @media screen and (max-width: 825px)
        flex: 0 1 33%
        order: 2
      @media screen and (max-width: 500px)
        flex: 0 1 28%
    @media screen and (max-width: 825px)
      .rhap_total-time
        order: 4
      .rhap_current-left-time
        order: 6
      .first-order
        order: 1
      .second-order
        order: 3
    .standard-col
      flex: 0 1 10%
      justify-content: center
      margin: 0
      @media screen and (max-width: 825px)
        flex: 0 1 33%
        border: none
        height: auto
    .rhap_volume-controls
      flex: 0 1 10% !important
      justify-content: center !important
      @media screen and (max-width: 825px)
        order: 7
        flex: 0 1 15% !important
      @media screen and (max-width: 500px)
        flex: 0 1 30% !important
    @media screen and (max-width: 825px)
      .play-pause-layer-1
        height: 35px
        width: 35px
      .play-pause-layer-2
        height: 45px
        width: 45px
      .play-pause-layer-3
        margin: 0
        height: 55px
        width: 55px
      .play-pause-m-container,.play-p-icon
        height: 30px
        width: 30px
.song-info-section
  max-width: 1460px
  padding-left: 0
  padding-right: 0
  .mobile-add-button
    display: none
  .card-header
    background-color: transparent
    border: none
    display: flex
    padding: 0
    .custom-check-container
      border: none
      margin: 0
      flex: 1 1 auto
      cursor: default
      @media screen and (max-width: 767px)
        cursor: pointer
      .form-check
        display: flex
        padding-left: 0
        height: 100%
        .title
          height: 100%
        .form-check-input
          display: none
          align-self: center
    .title
      padding: 5px
      flex: 1 1 auto
      @media screen and (max-width: 767px)
        font-size: 18px
        padding: 0
    .dropdown-col
      display: none
      padding: 0.75rem 1.25rem
      width: 100px
      align-self: center
      text-align: center
      justify-content: center
      height: 84px
      border-left: 1px solid #eeeeee
      cursor: pointer
      svg
        align-self: center
  .product-card
    transition: all .7s
    height: 100%
    &.border-warn
      border: 1px solid rgba(54, 58, 68, 0.8)
      animation: scaling .7s
    @keyframes scaling
      0%
        transform: scale(1)
      50%
        transform: scale(1.04)
      100%
        transform: scale(1)

  .song-comp-m-container,.song-comp-i,
  .stems-mask-container,.stems-icon
    height: 46px
    width: 46px
    margin-left: auto
    margin-right: auto
  .select-button-container
    transition: all .7s
    position: absolute
    text-align: center
    justify-content: center
    width: 91%
    bottom: 25px
    transform: scale(1)
    @media screen and (max-width: 767px)
      display: none
  .find-button
    display: block
    width: 100%
    margin-left: auto
    margin-right: auto
    height: 49px
  .exclusive-m-container,.exclusive-icon
    height: 46px
    width: 46px
    display: block
    cursor: pointer
    border-radius: 100%
  .exclusive-icon
    mask: url("../../../icons/exclusive.svg") no-repeat center
    background-color: #ffffff
  .exclusive-icon.royalty
    mask: url("../../../icons/royalty_free.svg") no-repeat center
  .exclusive-icon-small
    mask-size: 17px

  .exclusive-m-container
    background: linear-gradient(90deg, rgba(255,102,51,1) 0%, rgba(235,76,93,1) 100%)
  .lyrics-card
    text-align: left
    .card-body
      overflow-y: scroll !important
      overflow-x: hidden !important
    h5
      margin-top: 15px
    .lyrics-song-title
      font-family: "Roboto Light"
  .exclusive-col
    margin-top: 30px
    .checked-rows .col-lg-12
      margin: 0
      padding: 0
    .card-body
      .exclusive-flex-container
        display: flex
        flex-wrap: wrap
        align-items: center
        .exclusive-title
          margin-right: 10px
          @media screen and (max-width: 992px)
            flex: 0 1 100%
            justify-content: center
            display: flex
        .exclusive-text
          flex: 0 1 34%
          @media screen and (max-width: 1166px)
            flex: 0 1 22%
          @media screen and (max-width: 992px)
            flex: 0 1 100%
            text-align: center
            .col-lg-12
              justify-content: center
        .exclusive-select
          margin-right: auto
          display: flex
          justify-content: center
          @media screen and (max-width: 992px)
            flex: 0 1 100%
            display: flex
            margin-right: initial !important
            justify-content: center
          .form-check
            height: 49px
            .form-check-label
              height: 49px
              .title
                padding: 0
        .exclusive-button
          flex: 0 1 auto
          @media screen and (max-width: 992px)
            flex: 0 1 100%
          button
            height: 49px
            font-size: .9rem
            width: 188px
            @media screen and (max-width: 1026px)
              font-size: 13px
              width: 100%
        .custom-check-container
          width: 162px
          padding: 0 10px
          @media screen and (max-width: 992px)
            width: 100%
          .form-check-label
            align-items: center
          .title
            font-size: 18px !important
            margin-top: 5px
      .form-check
        display: flex
        .form-check-input
          align-self: center
        .form-check-label
          width: 100%
          display: flex
          justify-content: center

    .custom-check-container
      width: 90%
      min-width: 160px
      padding: 0
      .title
        font-size: 18px !important
        margin-top: 5px
    .title
      font-size: 20px
      justify-content: center
    .find-button
      margin-top: 0
  .song-cards
    .collapsed-card
      height: 0
      min-height: 0 !important
      padding: 0
      .select-button-container
        transform: scale(0)
    .song-section-cards
      margin-top: 30px
      .card-body
        transition: all .7s
        min-height: 465px
        overflow: hidden
      &:first-of-type
        .card-body
          background-color: #eee
          height: 573px
          min-height: 573px
  .custom-check-container
    padding: 10px
    cursor: pointer
    transition: all .3s
    border: 1px solid #e0e7ff
    margin: 10px 0
    border-radius: 5px
    .form-check
      pointer-events: none
    .form-check-input
      margin-top: 0
      transform: scale(1.2)
    .form-check-label
      font-size: 24px
      font-family: Lato, sans-serif
      font-weight: bold
      color: #222b45
      .song-comp-m-container,.stems-mask-container
        margin: 0 15px
    &.price-disabled
      .custom-check-container
        opacity: 0.7
        cursor: not-allowed
  .custom-check-container.custom-checked
    border: 1px solid #0676ed
    .form-check-label,.month-div
      color: #0676ed !important
  @media screen and (max-width: 767px)
    .mobile-add-button
      display: block
    .card-header
      background-color: rgba(0, 0, 0, 0.03)
      border-bottom: 1px solid rgba(0, 0, 0, 0.125)
      .form-check
        padding-left: 1.25rem !important
      .form-check-input
        display: block !important
      .dropdown-col
        display: flex
.scaled-checkbox
  transform: scale(1.4)

button[disabled]
  cursor: default !important