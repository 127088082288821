footer
  background: none repeat scroll 0 0 #202530
  padding: 25px 0
  font-family: Lato
  .footer-container
    max-width: 1366px
  .footer-menu-header
    font-size: 16px
    font-family: Lato
    color: #ededed
  .logo-container
    img
      height: 60px
  .row .col
    padding: 0 25px
    a
      font-size: 14px
  .menu-column
    min-width: 294px
    margin-bottom: 10px
    text-align: left
    p

      font-size: 15px
      margin: 10px 0 0 0
      line-height: 20px
      a
        color: #6d7783
  a:hover
      color: #fff
      text-decoration: none
.footer-bottom
  font-family: Lato
  height: 50px
  width: 100%
  text-align: center
  background-color: #333337
  color: #ededed
  padding: 13px 0
  font-size: 12px

.toast
  transition: all .5s
  position: fixed
  right: 0
  bottom: -200px
  width: 300px
.toast.show-toast
  bottom: 0





