@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700&display=swap')
.home-page
  scroll-behavior: smooth
  font-family: Lato, sans-serif
  .oval-div
    display: none
  .oval-div
    background-color: #ffffff
  .oval-div.actors
    bottom: -50px
    z-index: 1
  .centerable
    display: flex
    align-items: center
    justify-content: center
    flex-wrap: nowrap
  .hero-parallax2
    background-image: url(../../../images/rocketsongs.jpg)  !important
    background-position: center
    height: 700px
    max-height: initial
    .header-title-child
      font-size: 50px
      color: #ffffff
      font-weight: bold
      text-align: center
      text-transform: none
      left: 0
      right: 0
      margin: auto
    .header-subtitle-child
      width: 80%
      color: #92a5c3
      font-size: 35px
      font-family: Roboto, sans-serif
      text-align: center
      left: 0
      right: 0
      margin: 15px auto
      font-weight: 400
      text-transform: none
    .header-button
      margin-top: 40px
      button
        font-size: 18px
        font-weight: bold
        width: 350px
  .second-section-parent
    position: relative
    .background-1
      top: 1580px !important
    .background-2
      right: 0
      top: 700px !important
    .background-3
      right: 0
      top: 2020px !important
  .home-title
    color: #000000
    font-size: 42px
    font-weight: bold
  .center-div
    position: absolute
    top: 50%
    transform: translate(0, -50%)
  .contact-us-container,.contact-us-container .contact-us-row
    min-height: 800px
    position: relative
    padding: 20px 0
    .background-1
      top: 0 !important
    .background-2
      right: 0
      top: 140px !important
    .contact-us-text
      margin: 60px 0
      text-align: center
      .home-title
        max-width: 400px
      .rocket-logo
        margin-bottom: 60px
    .contact-us-card
      margin: 60px 0
      border-radius: 10px
      padding: 40px
      width: 92%
      height: 540px
      box-shadow: 0 20px 60px 0 rgba(0, 0, 0, 0.2), 0 2px 8px 0 rgba(0, 0, 0, 0.1) !important
      @media screen and (max-width: 992px)
        width: 100%
      .form-label
        color: #8d8d8d
        font-size: 12px
      .simple-orange-button
        width: 100%
      .form-group
        margin-bottom: 12px
      input.form-control
        height: 60px
      textarea.form-control
        height: 150px
  .opinions-section
    position: relative
    padding: 50px
  .opinions-section,.opinions-section .container,.opinions-section .row
    background-color: #0b2233
    .opinion-card
      position: relative
      padding: 15px
      margin: 10px 0
      background-color: rgba(255, 255, 255, 0.08)
      width: 100%
      min-height: 172px
      border-radius: 10px
      .opinion-date
        font-family: 'Source Sans Pro', sans-serif
        font-size: 12px
        position: absolute
        bottom: 10px
        color: #999696
      .opinion-text
        font-size: 12px
        color: #cecece
        margin: 15px 0
      .opinion-title
        display: flex
        align-items: center
        font-family: 'Source Sans Pro', sans-serif
        .opinion-username
          font-size: 12px
          color: #ffffff
    .opinion-cards-container
      min-height: 740px
      width: 100%
      overflow: hidden
    .opinion-text-container
      .opinions-t
        color: #ffffff
        font-size: 14px
      .opinions-title
        color: #ffffff
        font-weight: bold
        font-size: 40px
      .opinions-text
        color: #ffffff
        font-size: 14px
        margin-top: 20px

  .second-section
    position: relative
    z-index: 3
    .first-image,.first-text,.second-image,.second-text
      margin-bottom: 80px
    .definition-title,.pickup-title
      color: #000000
      font-weight: bold
      font-size: 52px
    .pickup-title
      margin-bottom: 32px
    .hiddenresp
      display: none
    .pick-your
      position: relative
      min-height: 400px
      .pick-your-container
        position: absolute
        top: 50%
        transform: translate(0, -50%)
        div
          padding: 0 20px
        .pickup-title
          color: #000000
          font-weight: bold
          font-size: 52px
          margin-bottom: 32px
    .genres-container
      .single-genre
        width: 100%
        background-color: red
        border-radius: 5px
        margin-bottom: 30px
        position: relative
        background-size: cover
        background-position: bottom
        cursor: pointer
        transition: all .2s
        &:hover
          transform: scale(1.1)
        &.not-clickable
          cursor: default
          &:hover
            transform: scale(1)
        div
          font-size: 18px
          color: #ffffff
          font-weight: 600
          position: absolute
          bottom: 8px
          left: 10px
    .image-bub-container
      position: relative
      height: 560px
      width: 464px

      .bubble,.bubble-border
        position: absolute
        height: 488px
        width: 488px
        border-radius: 100%
      .bubble-border
        border: 5px solid #8493a4
        top: 125px
        left: -100px
      .bubble
        z-index: 2
        background-color: #c1d7f096
        top: 100px
        left: -100px
      .image
        z-index: 3
        height: 560px
        width: 464px
        border-radius: 10px
        position: absolute
        background-size: cover
        background-position: top
    .text-definition
      div
        padding: 0 27px
      .definition-content
        font-size: 22px
        color: #333333
        border-left: 3px solid #ff5529
      .definition-button
        margin-top: 50px

  .produced-for-section
    position: relative
    z-index: 1
    overflow: hidden
    .produced-title
      z-index: 5
      color: #000000
      font-size: 42px
      font-weight: bold
      text-align: center
      margin: 40px auto
      max-width: 629px
    .written-for-arts
      margin: 20px auto
      //overflow: auto
      display: flex
      flex-wrap: nowrap
      transition: all 10s
      .single-artist
        width: 160px
        height: 220px
        text-align: center
        flex: auto
        margin: 0 20px
        img
          height: 156px
          width: 156px
          border-radius: 100%
        div
          color: #000000
          font-weight: bold
          font-size: 18px
@media screen and (max-width: 992px)
  .contact-modal-body
    width: 576px
    .modal-close
      margin-bottom: 10px
  .home-page
    .definition-button
      text-align: center
    .genres-container
      justify-content: center
    .second-section
      .first-image,.first-text,.second-image,.second-text
        margin-bottom: 0 !important
        display: flex
        justify-content: center
        flex-wrap: wrap
      .second-text,.first-text
        margin-bottom: 40px !important
    .hero-parallax2
      height: 700px
      .header-title-child
        width: 80%
        font-size: 46px
        text-align: left
        margin-left: 30px
      .header-subtitle-child
        width: 80%
        font-weight: lighter
        font-size: 30px
        text-align: left
        margin-left: 30px
      .header-button
        margin-top: 60px
        .simple-orange-button
          height: 64px
          width: 340px
    .opinions-section .row
      text-align: center !important
    .produced-for-section
      .produced-title
        font-size: 30px
        margin: 30px auto
      .written-for-arts
        .single-artist
          margin: 0 10px
          img
            height: 100px
            width: 100px
    .definition-title
      display: none !important
    .pickup-title
      display: none !important
    .pick-your
      min-height: 200px !important
    .contact-us-text
      margin: 20px 0 !important
    .opinions-section
      padding: 50px 0 !important
    .container
      padding: 0 !important
    .contact-us-card
      padding: 0 !important
      margin: 20px 0 !important
      .simple-orange-button
        width: 100% !important
    .definition-title.hiddenresp,.pickup-title.hiddenresp
      font-size: 42px !important
      display: block !important
      text-align: center
      width: 500px !important
      margin: 30px  0!important
    .image-bub-container
      height: 460px !important
      width: 50% !important
      .bubble
        top: 85px !important
      .bubble-border
        top: 105px !important
      .image
        height: 400px !important
        width: 320px !important
        right: 0
        left: 0
      .bubble,.bubble-border
        left: initial !important
        right: 20px !important
        height: 350px !important
        width: 350px !important
@media screen and (max-width: 576px)
  .contact-modal-body
    width: 320px
  .home-page
    .hero-parallax2
      background-image: url(../../../images/homeBackMobile.jpg)  !important
    .second-section
      .image-bub-container
        height: 460px !important
    .hero-parallax2
      height: auto
      .header-button
        .simple-orange-button
          width: 320px

    .definition-title.hiddenresp,.pickup-title.hiddenresp
      font-size: 42px !important
      display: block !important
      text-align: center
    .image-bub-container
      width: 100% !important
      .image
        height: 346px !important
        width: 286px !important
        left: initial !important
        right: -15px

