.load-more-btn
  font-family: 'Lato', sans-serif
  color: #fff
  background-color: #f63 !important
  border-color: transparent
  text-transform: uppercase
  padding: 12px 0
  height: 50px
  width: 200px
  margin-left: auto
  margin-right: auto
  border-radius: 7px
  font-weight: bold
.load-more-btn .spinner-border
  margin-top: -4px
.songs-table
  .shop-col
    .dropdown-toggle
      width: 83px
    .dropdown-menu
      left: -33px
  .card-body
    padding: 0
    background-color: #fafafa

.single-song-card
  border: 1px solid #eee
.carret-col
  font-size: 30px
  cursor: pointer
  text-align: center
  border-right: 1px solid #eeeeee
  height: 100px
  width: 100px
.single-song-list .dropdown-toggle::after
  position: absolute
  right: 10px
.filtered-dropdown
  .dropdown-toggle,.dropdown-item,.dropdown-item:hover,.dropdown-toggle:hover
    background-color: transparent !important
    color: #8e96ab !important
    padding: 0 !important
    text-align: center
  .dropdown-toggle::after
    border: none

  .dropdown-menu.show
    max-height: none
    min-width: 0
    overflow: hidden
    width: 100%

  .dropdown-item
   padding: 0
  .btn
    font-size: 12px
  .btn:disabled
    opacity: .5
  .dropdown-toggle
    border: none !important

.carret-col  svg
  transition: all .5s
.carret-col.collapse-open,.clearfilters-button.collapse-open
  svg
    transform: rotate(-180deg)
.play-button-col
  background: url("../../../icons/play.svg") no-repeat center
  padding: 0
  cursor: pointer
  height: 46px
  width: 46px
.play-button-col.current-playing
  background: url("../../../icons/play_pause.svg") no-repeat center
.single-song-list
  display: flex
  flex-wrap: wrap
  margin: 0
  align-items: center
.standard-col,.filtered-dropdown .dropdown-item
  text-align: center
  font-size: 12px
  color: #8e96ab
.stem-icon,.details-icon,
.favorite-icon,.buy-icon,
.favorite-mask-container,
.buy-mask-container,.d-song-mask-container,
.d-song-icon,.d-stems-mask-container,
.d-stems-icon,.stems-mask-container,.stems-icon,
.song-comp-m-container,.song-comp-i
  height: 46px
  width: 46px
  display: block
  margin-left: auto
  cursor: pointer
  margin-right: auto
  border-radius: 100%
.collapse-check-icon-container,.collapse-check-icon
  border-radius: 100%
  height: 32px
  width: 32px
.dropdown-toggle
  cursor: pointer
.collapse-check-icon
  background: url("../../../icons/check.svg") no-repeat center
.collapse-check-icon-container
  background-color: rgba(0, 172, 90, 0.1)
  margin-right: 15px
.d-song-icon,.d-stems-icon,.stems-icon,
.favorite-icon,.buy-icon
  height: 100% !important
  width: 100% !important
.details-icon
  background: url("../../../icons/info.svg") no-repeat center
  background-color: #fff
  border: 1px solid #d0d3dc
.d-song-icon
  mask: url("../../../icons/song_only.svg") no-repeat center
  background-color: #545e79
  border: 1px solid #d0d3dc
.d-stems-icon
  mask: url("../../../icons/stem.svg") no-repeat center
  background-color: #545e79
  border: 1px solid #d0d3dc


.stems-icon
  mask: url("../../../icons/stem.svg") no-repeat center
  background-color: #bec2cb
.stems-mask-container
  background-color: #edeef0

.song-comp-i
  mask: url("../../../icons/song_only.svg") no-repeat center
  background-color: #0676ed

.song-comp-m-container
  background-color: #e4f0fc

.has
  .stems-icon
    background-color: #0676ed
  .stems-mask-container
    background-color: #e4f0fc
  .song-comp-i
    mask: url("../../../icons/song_track.svg") no-repeat center
.favorite-icon
  mask: url("../../../icons/favorite.svg") no-repeat center
  background-color: #545e79
.favorite-mask-container
  background-color: #ffffff
  border: 1px solid #d0d3dc
  transition: all .2s
  cursor: pointer
.myfav
  .favorite-mask-container
    background-color: #fec240
  .favorite-icon
    background-color: #ffffff
.buy-icon
  mask: url("../../../icons/buy.svg") no-repeat center
  background-color: #545e79
  transition: all .2s

.buy-mask-container
  background-color: #ffffff
  border: 1px solid #d0d3dc
  transition: all .2s

.show
  .buy-mask-container
    background-color: #0575ec
  .buy-icon
    background-color: #ffffff
.d-song-mask-container,.d-stems-mask-container
  background-color: #ffffff
  border: 1px solid #d0d3dc
.song-col-row-container
  margin: 14px
  border: 1px solid rgba(0, 0, 0, 0.125)
  border-radius: 0.25rem
.favorites-container
  .song-col-row-container
    margin: 14px 0
.collapse-row
  margin: 25px
  @media screen and (max-width: 690px)
    margin: 25px 0
  //justify-content: center
.collapse-row .col-lg-5:nth-of-type(2)
  border-left: 1px solid #d0d3dc
  padding-left: 30px
.collapse-row .col-lg-5:nth-of-type(1)
  padding: 0
.song-info-section
  .title
    align-items: center
.collapse-row
  .title
    align-items: end
.collapse-row,.song-info-section
  .title
    display: flex
    font-family: Lato, sans-serif
    font-weight: bold
    font-size: 26px
    line-height: 32px
  .exclusive-col
    .title
      display: block
  .month-div
    font-family: Roboto
    font-weight: 400
    font-size: 12px
    color: #222b45
  .checked-rows,.song-info-section
    .col-lg-6,.col-lg-12
      display: flex
      align-items: center
      font-family: Roboto
      margin: 10px 0
      font-size: 15px
      padding: 0
.new-song-badge
  background-color: #c8f2e4
  margin-left: .5rem
  border-radius: 1rem
  padding: .2rem .6rem
  font-size: 75%
  font-weight: 700
  line-height: 1
  color: #00d18b
  text-align: center
  white-space: nowrap
  vertical-align: baseline
.carret-col
  padding: 30px
  flex-grow: 0
.play-button-col
  flex: 0 1 7%
.title-col
  flex: 0 1 15%
  display: flex
  flex-wrap: wrap
  align-items: center
  div
    flex: 1 1 100%
.owner-row
  flex: 0 1 20%
.details-col
  flex: 1 1 auto
.track-type-col
  flex: 0 1 10%
.stem-col,.track-type-col
  div
    cursor: default !important
.stem-col,.favorites-col,.shop-col
  flex: 0 1 7%
@media screen and (max-width: 992px)
  .owner-row
    display: none
  .play-button-col
    flex: 0 1 10%
  .title-col
    flex: 0 1 22%
  .track-type-col
    flex: 0 1 13%
  .details-col
    flex: 0 1 12%
  .stem-col,.favorites-col,.shop-col
    flex: 0 1 7%
@media screen and (max-width: 770px)
  .carret-col
    order: 7
    flex: 0 1 20%
    border-left: 1px solid #eee
    border-top: 1px solid #eee
    border-right: none
  .play-button-col
    height: 82px
    border-bottom: 1px solid #eee
    order: 0
    flex: 0 1 11%
  .title-col
    height: 82px
    border-bottom: 1px solid #eee
    order: 1
    flex: 0 1 68%
  .track-type-col
    order: 3
    flex: 0 1 20%
    height: 64px
  .stem-col
    order: 4
    flex: 0 1 20%
  .details-col
    order: 5
    flex: 0 1 20%
    margin: 0
  .favorites-col
    order: 6
    flex: 0 1 20%
    margin: 0
  .shop-col
    display: flex
    justify-content: center
    align-items: center
    height: 82px
    border-bottom: 1px solid #eee
    flex: 0 1 15%
    order: 2

@media screen and (max-width: 440px)
  .songs-table
    padding: 0 !important
  .carret-col
    flex: 0 1 28%
    padding: 25px
    @media screen and (max-width: 380px)
      flex: 0 1 22%
  .play-button-col
    flex: 0 1 16%
  .title-col
    flex: 0 1 50%
  .track-type-col,.stem-col,.details-col,.favorites-col
    flex: 0 1 18%
    @media screen and (max-width: 325px)
      flex: 0 1 17%
  .shop-col
    flex: 0 1 15%
