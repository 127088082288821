.error-page
  text-align: center
  .error-code
    font-size: 160px
    color: #f63
    text-shadow: 6px 6px #f00
    span
      font-size: 50px
      text-shadow: 1px 1px #f00
      margin-left: 25px
  .back-home-btn
    background: #fe5629 !important
    color: #ffffff
    border-color: #fe5629
