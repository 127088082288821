.notification-warn
  transform: scale(0)
  position: fixed
  width: 60%
  min-height: 100px
  top:  50%
  left: 20%
  z-index: 10000
  border-radius: 50px
  margin: auto
  display: flex
  justify-content: center
  align-items: center
  flex-wrap: nowrap
  div
    font-family: Lato, sans-serif
    font-weight: bold
  div:first-of-type
    font-size: 24px
    flex: 1 100 auto
    text-align: left
    padding-left: 30px
    @media screen and (max-width: 500px)
      font-size: 16px
  div:last-of-type,svg
    height: 60px
    width: 60px
    display: block
    margin-left: auto
    margin-right: auto
    cursor: pointer
    border-radius: 100%
    flex: 0 1 auto
    @media screen and (max-width: 500px)
      flex: none
  div:last-of-type
    margin-right: 20px
    background-color: #6d727b
  svg
    color: #adb3bf
    font-size: 40px
  &.primary-nofification
    background-color: rgba(32, 37, 48, 0.9)
    color: #bfc4d0
    div:last-of-type
      background-color: #6d727b
      svg
        color: #adb3bf
  &.success-nofification
    background-color: rgba(7, 188, 102, 0.9)
    color: #ffffff
    div:last-of-type
      background-color: #49d492
      svg
        color: #e1ffee
  &.error-notification
    background-color: rgba(234, 84, 85, 0.9)
    color: #ffe1e1
    div:last-of-type
      background-color: #fa8081
      svg
        color: #ffdada
  &.yellow-nofification
    background-color: rgba(237, 175, 50, 0.9)
    color: #fff4dd
    div:last-of-type
      background-color: #f7c664
      svg
        color: #fff1d4
  @media screen and (max-width: 500px)
    width: 100%
    left: 0
.notification-warn.show
  transform: scale(1)
  .container
    padding: 0
