@import "~bootstrap/scss/bootstrap"
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;500;600;700;900&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700&display=swap')
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap')
@import "/base"

html
  overflow-x: hidden

*
  box-shadow: none !important

html, body, #root
  height: 100%

h1, h2, h3, h4, h5, h6
  font-weight: bold
  margin-bottom: 10px
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif

.cart-royalty-container
  display: flex
  gap: 15px
  justify-content: center
.tooltiped
  text-decoration: underline
  font-weight: bold
  cursor: help
  img
    max-height: 20px
    margin-bottom: 5px
    margin-left: 3px
.checkbox-container
  display: flex
  justify-content: center
  align-content: center
  input
    transform: scale(2)
h5
  font-size: 14px
  line-height: 20px
  margin-top: 10px

select
  -webkit-appearance: none
  -moz-appearance: none
  background: transparent
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>")
  background-repeat: no-repeat
  background-position-x: 97%
  background-position-y: 50%

button:focus
  outline: none !important

.find-button:hover, .load-more-btn:hover, .search-button:hover, .simple-orange-button:hover,
.simple-orange-button:hover, .start-shop-button button:hover, .last-but button:hover, .btn.login:hover
  transition: all .2s
  background-color: #de592c !important

.details-icon:hover, .favorite-mask-container:hover, .buy-mask-container:hover, .d-song-mask-container:hover, .d-stems-mask-container:hover
  transition: all .2s
  background-color: #eeeeee

.border-bubble
  background-image: url("../../icons/hiwbg.png")
  right: -362px
  top: 20%
  height: 500px
  width: 500px
  position: absolute
  background-size: cover

  &.left
    right: initial
    left: -360px

.simple-orange-button
  font-family: 'Lato', sans-serif
  font-weight: bold
  height: 52px
  width: 250px
  border-radius: 5px
  color: #fff
  background-color: #f63 !important
  border-color: transparent
  text-transform: uppercase

.btn-primary:active
  background-color: #ff5628 !important
  border-color: #ff5628 !important

.highlight
  color: #ff5628 !important

.breadcrumb-item.active a
  color: #ff6633

.pattern-bg
  background: #ffffff url(../../images/pattern.png) repeat

.disabled-button
  opacity: .5

  div
    cursor: default !important
//contact us start
.contact-section
  margin: 65px 0

.contact-modal-body
  width: 600px
  background: #dddddd url(../../images/pattern.png) repeat

.contact-modal-body h4
  color: #666666
  font-size: 18px
  line-height: 24px
  margin-top: 10px

.contact-modal-body span
  text-align: right

.contact-modal-body .modal-close
  background: #fff
  padding-left: 20px
  padding-right: 20px
  padding-bottom: 15px
  opacity: 1
  color: #ff5628
  font: 300 45px 'lato', sans-serif
  border-radius: 0
  border: none
  outline: none

.contact-section .container, .no-padding
  padding: 0 !important

.no-padding-left
  padding-left: 0 !important

.contact-section .title
  color: #ff6633
  font: bold 21px 'arimo', sans-serif
  margin-bottom: 30px
  text-transform: uppercase


.subtitle
  color: #ff6633
  font: 300 33px 'lato', sans-serif
  margin-bottom: 5px

.contact-section input, .contact-section textarea
  border: 1px solid #ff6633 !important
  border-radius: 0

.centered-content
  display: flex
  align-items: center
  justify-content: center

.contact-form-group
  padding: 0
  top: 40px
  text-align: right

.btn-class
  transition: all 0.3s ease-in-out
  background: #ff6633
  padding: 10px 54px
  color: #fff !important
  text-transform: uppercase
  font-weight: 900
  font-size: 16px
  font-family: open sans
  border: none
  border-radius: 2px
//contact us end
//faq start
.faq
  padding-bottom: 50px
  padding-top: 50px

  .title
    padding-bottom: 30px

  h1
    text-transform: uppercase
    font-size: 20px
    font-weight: 400

  ol li
    font: 300 20px/32px 'lato', sans-serif

  h2
    font-weight: 400
    font-size: 20px
    color: #ff5628

.faq a
  font: 300 18px/32px 'lato', sans-serif
  color: #222B45
  line-height: 1.5

  &:hover
    color: #ff5628

.faq p
  font: 300 20px/32px 'lato', sans-serif
  color: #282828
//license pages start
.licenses-content
  position: relative
  margin: 60px 0

  ul
    padding-left: 20px

  ol
    padding-left: 17px

.licenses-content p
  font: 300 20px/32px 'lato', sans-serif
  color: #282828

.licenses-content p a, .licenses-content li a, .faq p a, .faq li a, .rs-link
  color: #ff5628 !important
  font-weight: 500

.licenses-content .dark-title
  font-size: 20px
  font-weight: 400
  color: #282828

.licenses-content .title
  text-transform: uppercase
  font-size: 20px
  font-weight: 400

.more-row
  margin: 30px

.font-18
  font-size: 18px

.licenses-content li
  color: #282828
  font: 300 20px 'lato', sans-serif

.licenses-content p strong
  font-weight: 600

.btn-orange
  margin-top: 50px
  text-transform: uppercase
  background-color: #fe5629
  padding: 18px 95px
  font: normal 16px 'arimo', sans-serif
  color: #ffffff

//Team
.team-container
  position: relative
  padding: 60px 0

.team-container p
  font: 300 20px/32px 'lato', sans-serif
  color: #282828

.team-container img
  max-width: 300px
  margin-top: 45px
  margin-bottom: 30px
//AboutUs

.hidden
  display: none !important


//legal page
.legal-content h3
  font-size: 18px
  font-weight: 400
  text-transform: uppercase

//Content Partner Agreement
.bit-title
  text-align: center
  font-size: 30px !important
  margin-bottom: 10px
///sidebar
.sidebar
  height: 100vh
  overflow-y: scroll
  -webkit-overflow-scrolling: touch
  overflow-x: hidden
  position: fixed
  right: -400px
  top: 0
  z-index: 2000
  transition: right .5s
  background-color: #ffffff

.circled
  position: absolute
  margin: .3rem
  color: #f63
  background-color: #f5f5f5
  padding: .2rem 0
  font-family: Lato
  width: 1.8rem
  height: 1.8rem
  border-radius: 1rem
  text-align: center

.sidebar.is-nav-open
  right: 0

.hideblock
  background: rgba(0, 0, 0, .85)
  position: fixed
  top: 0
  left: 0
  right: 0
  border: 0
  width: 100%
  height: 100%
  z-index: 0
  opacity: 0
  visibility: hidden
  transition: all .3s ease-in-out

.sidebar-logged-buttons svg
  margin-right: 6px

.hideblock.show-block
  visibility: visible
  opacity: 1
  z-index: 1500

.close-container
  height: 60px
  z-index: 100

.sidebar-content .close
  background: #fff
  padding: 15px
  opacity: 1
  color: #c2c3c9
  font: 300 35px 'lato', sans-serif
  outline: none
  height: auto
  margin: 0

.sidebar-content .title
  color: #8E96AB !important
  font: 700 28px "lato", sans-serif !important
  text-align: left

.sidebar-content .subtitle
  color: #959595
  font: 300 14px/24px "arimo", sans-serif
  margin-bottom: 20px
  margin-top: 5px
  text-align: left

.btn-facebook
  background-color: #3B5998
  color: white !important

.btn-facebook:hover
  background-color: #355089 !important


.sidebar-content .btn
  height: 45px
  margin-bottom: 10px
  width: 100%
  font: 500 16px 'open sans', sans-serif

.sidebar-content input, .sidebar-content .form-control
  width: 100%
  padding: 10px 15px
  box-sizing: border-box
  margin: 0 0 20px
  color: #666
  display: block
  clear: both
  height: 45px
  font: normal 16px 'arimo', sans-serif

.sidebar-content .login
  background: #fe5629
  color: white

.marging-bottom
  margin-bottom: 20px
  text-align: left

.sidebar-content .forgot
  color: #0676ed

  &:hover
    transition: all .2s

.reg-type-select option
  font-size: 18px
  padding: 5px

.sidebar-content .reg-email
  background-color: #ffffff
  border: solid 2px #ff6633
  color: #ff6633

  &:hover
    background-color: rgba(254, 88, 42, .2)


.sidebar-content .text
  color: #8E96AB
  font: 400 10px "arimo", sans-serif
  padding-bottom: 40px
  margin-top: 15px

.sidebar-content .text a
  font-weight: 800
  color: #2a6496
  text-decoration: underline

.sidebar-logged-buttons
  background-color: transparent
  color: #545E79
  text-transform: uppercase
  border-radius: .7rem
  padding: .5rem 1.2rem
  font-size: 16px
  font-weight: 400
  display: inline-block
  line-height: 1.25
  text-align: center
  white-space: nowrap
  vertical-align: middle

.sidebar-logged-buttons:hover
  color: #ff6633
  text-decoration: none

.logged-row p
  font-size: .75rem
  color: #545E79 !important

.sidebar-logged-socials
  font-size: 1.5rem
  color: #8798AD
  text-decoration: none

.sidebar-logged-socials.youtube:hover
  color: #e52c27

.sidebar-logged-socials.facebook:hover
  color: #3b5998

.sidebar-logged-socials.twitter:hover
  color: #3399fe

.show.tooltip
  z-index: 900000000

.sidebar-logout-button
  text-transform: uppercase
  text-decoration: none
  border-radius: .7rem
  line-height: 1.25
  text-align: center
  color: #8798AD
  width: auto !important
  display: block
  white-space: nowrap
  border: none
  background-color: transparent !important
  vertical-align: middle
  padding: .5rem 2rem

.sidebar-logout-button:hover
  color: #fff
  background-color: rgba(142, 150, 171, 0.7) !important
///Main menu


.panel-collapse
  max-height: 0
  overflow: hidden
  transition: 0.5s ease-out

.panel-collapse.show
  max-height: 500px
  transition: 0.5s ease-in


.panel-body
  padding: 15px


/////Home page second section



.last-impression
  background-color: #ff6633
  padding: 40px 0

.last-impression .title
  color: #fff
  font: 300 45px 'lato', sans-serif
  margin-top: 0


.breadcrumb-container
  font-size: 18px

.breadcrumb a
  color: #f63
  text-underline: none
  text-transform: uppercase

.breadcrumb-item
  z-index: 2

.breadcrumb-item:hover a
  color: #ffffff
  text-decoration: none

.brand-logo .logo:hover
  -webkit-transform: translate(0px, -3px)
  -ms-transform: translate(0px, -3px)
  transform: translate(0px, -3px)


.hero-title
  position: absolute
  display: inline-block
  font-family: 'Open Sans', sans-serif
  color: white
  font-size: 52px
  line-height: 55px
  font-weight: 800
  letter-spacing: -2px
  transition: all 1s


.row
  margin-left: 0
  margin-right: 0

.hero-title span
  font-weight: 300

.scroll-comp img
  max-width: 100%
  vertical-align: middle
  display: inline-block

.slideshow-background
  position: absolute
  width: 100%
  background: black
  z-index: -1
  height: 794px
  top: 0


.scroll-comp
  position: absolute
  right: 35px
  bottom: 30px
  z-index: 2
  transition: all 150ms ease
  text-decoration: none
  text-align: center
  font-size: 13.5px
  cursor: pointer

.scroll-comp:hover
  text-decoration: none
  transform: translate(0px, 3px)

.explore-link
  font-family: 'Open Sans', sans-serif
  color: #f63
  line-height: 40px
  letter-spacing: -0.5px
/////shop


.shop-filters, .search-container, .shopping-page-content .songs-table
  padding: 0 25px


.dropdown-toggle, .dropdown-toggle:hover
  width: 100%
  border-color: #6d6d6e
  background: #fff !important
  color: #f63 !important
  padding: 1rem 0

.dropdown-menu.show
  width: 100%
  max-height: 8rem

.new-song-badge
  background-color: #f63
  margin-left: .5rem
  border-radius: 1rem
  padding: .2rem .6rem
  font-size: 75%
  font-weight: 700
  line-height: 1
  color: #fff
  text-align: center
  white-space: nowrap
  vertical-align: baseline

.btn-group
  width: 100%

.btn-group .btn
  flex-basis: 0
  flex-grow: 1
  padding: 1rem 0
  text-transform: uppercase
  background: #fff
  color: #f63
  border-color: #6d6d6e


.shop-filters
  .btn
    text-transform: uppercase

  .row > div
    margin: 1em 0

.filter-label
  margin-bottom: 0.5rem
  color: #6d6d6e
  font-size: .8rem
  font-weight: 400
  line-height: 1

.search-input
  flex-wrap: nowrap
  display: flex
  justify-content: center

  .form-control
    flex: 0 1 auto

.table-header-name
  font-family: 'Lato', sans-serif
  font-weight: 400
  line-height: 1.5
  color: #6d6d6e
  font-size: .8rem

.license-circle
  width: 2rem
  height: 2rem
  border-radius: 1rem
  line-height: 2rem
  font-size: .8rem
  font-weight: bolder
  margin: 0 .2rem
  text-align: center
  color: #fff
  background-color: #75acba

.license-col
  display: flex
  justify-content: center

.song-track-owner
  font-size: 1rem
  font-weight: 400
  line-height: 1.5
  color: #292b2c
  font-family: 'Lato', sans-serif

.song-track-owner:hover
  text-decoration: underline
  outline-width: 0

.song-title, .song-title-uploaded
  color: #000
  font-weight: 700
  font-family: 'Lato', sans-serif

.song-title:hover
  text-decoration: underline

.song-genres
  color: #6d6d6e
  font-family: 'Lato', sans-serif

.auth-content-up
  width: 100%

  .text
    text-align: left

.shop-button
  text-decoration: none
  color: #bcbcbc
  display: flex
  height: 55px
  width: 100%
  border: none
  background: #dfdfdf
  font-size: 26px
  align-items: center
  justify-content: center
  padding: .8rem 0
  cursor: pointer

.shop-button.current-playing
  color: #f63
  animation: playAnim 1s infinite

@keyframes playAnim
  0%
    font-size: 24px
  25%
    font-size: 26px
  50%
    font-size: 32px
  75%
    font-size: 26px
  100%
    font-size: 24px


.shop-button.myfav
  background-color: #fc0
  color: #fff

.shop-button:hover
  color: #f63

.shop-button.star:hover
  color: #fc0

.shop-button.myfav:hover
  color: #bcbcbc

.shop-button-container
  padding-right: .25rem !important
  padding-left: 0

.center-text
  text-align: center

.btn-group .btn-secondary:active
  background-color: #f63 !important
  color: #fff

.filters-body
  max-height: 778px
  transition: max-height 1s

.collapsed
  overflow: hidden
  max-height: 0

.mobile-filters-button
  float: left
  cursor: pointer
  background-color: transparent !important
  border: none !important
  font-family: 'Lato', sans-serif
  padding: .5rem
  color: #000 !important
  font-size: 26px
  font-weight: 600
  outline: none

.clearfilters-button
  float: right
  color: #000 !important
  background-color: transparent !important
  border: none !important
  padding: .5rem
  outline: none

.filters-row
  display: none

.filters-row .col
  margin: 0 !important

.dropdown-menu.show
  overflow: auto


.single-song-list .col .row .col-lg-12
  padding: 0

.single-song-list
  margin: 10px 0
////track owner
.last-impression.owner
  padding: 34px 0
  text-align: center

.trackowner-title
  font-size: 48px
  font-weight: 100
  font-family: 'lato', sans-serif
  color: #fff

.trackowner-subtitle-white
  color: #ffff
  font-size: 16px
  font-weight: 300
  font-family: 'lato', sans-serif

.trackowner-content-title
  text-transform: uppercase
  color: #ff5628
  margin-top: 0
  font-size: 16px
  line-height: 30px
  font-weight: 300
  font-family: 'arimo', sans-serif

.trackowner-content
  padding: 10px 0
  text-align: justify
  line-height: 28px
  font-size: 18px
  font-weight: 300
  font-family: 'lato', sans-serif
  color: #221f20


///track info (Songs.js)


.edit-song-btn
  width: 100%
  height: 53px
  border: none
  border-radius: 0
  font-size: 26px
  background-color: #ff5628 !important

.show-rocket-btn
  width: 100%
  height: 53px
  border: none
  border-radius: 0
  margin: 0
  background-color: rgb(185, 193, 54) !important
  padding: 6px 0 6px 35px

.show-rocket-btn.show-off
  background: url(../../images/checkbox-empty.png) 15px center no-repeat

.show-rocket-btn.show-on
  background: url(../../images/checkbox-full.png) 15px center no-repeat

.my-songs-title
  color: rgb(255, 86, 40)

.error-message
  color: red

.PlayerContainer
  position: fixed
  bottom: -200px
  width: 100%
  z-index: 2000
  left: 0
  transition: all .8s

.my-songs-preloader
  width: 4rem
  height: 4rem
  margin-left: 47%
  margin-top: 480px
  color: #f63

.fix-size-list
  min-height: 1146px

.rhap_progress-container, .rhap_volume-bar-area
  outline: none

.music-close
  position: absolute
  right: 0
  top: -15px
  font-size: 27px
  cursor: pointer
  height: 33px
  width: 33px
  border-radius: 100%
  color: #202530
  background-color: #f63

  svg
    top: -2px
    left: 0.5px
    position: relative

.dropdown-content .music-close
  position: absolute
  right: 0
  top: -10px
  font-size: 33px
  cursor: pointer
  color: #f63
  background-color: initial

.PlayerContainer.showplayer
  bottom: 0

.profile-input-labels
  color: #6d6d6e
  font-size: .8rem

.my-prof-song-title
  margin: 10px 0

.profile-update-btn
  background-color: #00AC5A
  display: block
  width: 100%
  height: 38px
  border-radius: 5px
  bottom: 0
  text-transform: uppercase
  border: none !important
  color: #ffffff

  &:hover
    transition: all .2s
    background-color: #009950 !important

.no-paddings
  padding: 0 !important

.white-wave, .black-wave
  background-image: url("../../images/whiteWave.png")
  background-repeat: no-repeat
  background-size: cover
  background-position: center
  position: absolute
  height: 49px
  width: 100%
  border: none
  z-index: 5

.white-wave.down, .black-wave.down
  transform: rotate(180deg)

.black-wave
  background-image: url("../../images/blackWave.png")

.gray-wave
  background-image: url("../../images/grayWave.png")

.profile-update-btn:disabled
  background-color: #b9c136
  cursor: not-allowed

.shopping-cart-fixed
  height: 55px
  width: 55px
  z-index: 999
  cursor: pointer
  position: fixed
  right: 40px
  bottom: 40px
  border-radius: 100%
  background-color: #0575ec
  color: #f63

.fixed-cart-icon
  position: absolute
  font-size: 22px
  left: 10px
  top: 12px
  margin-left: 4px
  margin-top: 4px
  color: #fff

.fixed-counter-count
  position: absolute
  font-size: 16px
  top: -1px
  font-weight: 600
  left: 38px
  color: #fff
  background: #f63
  border-radius: 100%
  height: 24px
  width: 24px

.profile-update-btn .spinner-border
  width: 1.2rem !important
  height: 1.2rem !important

.optional-tag
  font: 9px 'arimo', sans-serif
  color: #bdbdbd

.profile-sec-section
  background-color: #f1f4f5
  padding-bottom: 50px
  padding-top: 50px

.profile-sec
  padding-bottom: 50px
  padding-top: 50px

.prof-section-container
  font-size: 1rem
  font-weight: 400
  line-height: 1.5
  color: #6d7783
  font-family: 'Lato', sans-serif

.prof-section-container .songs-table .row .col
  padding-left: 0

.preloader
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  background-color: #fff
  z-index: 99999999

.status
  height: 300px
  width: 300px
  position: absolute
  top: 50%
  margin-top: -150px
  left: 50%
  margin-left: -150px
  background-repeat: no-repeat
  background-position: center

.spinner
  margin: 100px auto
  width: 32px
  height: 32px
  position: relative

.cube1, .cube2
  background-color: #ff6633
  width: 10px
  height: 10px
  position: absolute
  top: 0
  left: 0
  animation: cubemove 1.8s infinite ease-in-out

.cube2
  animation-delay: -0.9s

@keyframes cubemove
  25%
    transform: translateX(42px) rotate(-90deg) scale(0.5)
  50%
    transform: translateX(42px) translateY(42px) rotate(-179deg)
  75%
    transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5)
  100%
    transform: rotate(-360deg)

.my-prof-subtitle
  font-family: 'Lato', sans-serif
  font-size: 1.25rem

.profile-centered
  align-self: center
  text-align: center

  img
    max-width: 100%

.profile-sec-section p
  font-family: 'Lato', sans-serif

.remove-field-button
  margin-top: 26px !important
  background: transparent !important
  color: black !important
  border: none !important

.fullsizedOrangeBtn
  width: 100%
  padding: 15px
  margin: 10px 0
  background-color: #ff5628 !important
  color: #fff
  border-radius: 0
  border: none !important


.tox-tinymce
  border: 1px solid #f63 !important

.profile-centered .btn
  color: #fff
  background-color: #f63
  border-color: transparent
  text-transform: uppercase
  width: 100%

.add-tracks-button
  text-transform: uppercase
  font: 15px 'arimo', sans-serif
  border-radius: 0
  background: #75acba !important
  color: #fff
  width: 100%
  border: none !important

.add-tracks-button:hover
  background: #75acba
  color: #333
  text-decoration: none

.profile-search-input input
  height: 40px
  border: 1px solid #fe5629
  width: 100%
  border-radius: 0

.my-songs-table-header
  padding-bottom: 20px
  border-bottom: 1px solid #e1e1e1
  overflow: hidden
  margin: 0 10px

.rhap_container:focus
  outline: none !important

.play-button:disabled
  background-color: #ffffff
  border-color: #f63
  cursor: default

.rhap_main-controls
  display: none !important

.rhap_volume-button
  color: #f63 !important

.rhap_volume-indicator
  background: #ffffff !important
  border: 1px solid #f63 !important

.rhap_volume-bar
  background: #f63 !important

.current-plays
  background-color: #f63 !important
  background: #f63 url(../../images/pause.png) no-repeat 50% 50%

.track-info-container
  p
    color: #6d6d6e
    font-family: Lato
    font-size: .7rem

  a
    color: #f63
    font-weight: bolder

  span
    font-weight: bolder
    font-family: Lato
    font-size: .8rem

.royalities-earnings
  background: #fe5629

  .col-lg-6
    display: block
    padding: 15px
    font: 300 16px 'lato', sans-serif
    border-radius: 0
    position: relative
    color: #ffffff
    padding-left: 5px
    text-align: center
    white-space: nowrap
    vertical-align: baseline

  .col-lg-1
    color: #fe5629
    background: #ffffff
    align-self: center
    padding: 0
    text-align: center
    font-size: 26px
    margin-left: 20px

  .col-lg-4
    background: #b9c136
    color: #fff
    padding: 15px
    display: block
    font: 16px 'lato', sans-serif
    border-radius: 0
    border-left: 10px solid #fe5629
    text-align: left

.royalities-title
  color: #b9c136 !important
  text-transform: uppercase

.totals-container
  div
    background: #fe5629
    overflow: hidden
    margin-top: 30px
    height: 48px

  .col-lg-2
    color: #fff
    font: 300 16px 'lato', sans-serif
    align-self: center
    padding: 15px

  .col-lg-3:nth-child(4)
    background: #b9c136
    color: #fff
    font: 300 16px 'lato', sans-serif
    align-self: center
    padding: 15px

.profile-search-input button
  background: #fe5629 !important
  height: 40px
  width: 44px
  color: #ffffff
  border-color: #fe5629

.prof-sec-padding
  padding: 3rem 0
  margin: 1rem 0
  height: 100%

.btn-group .active
  background-color: #f63 !important
  color: #ffffff !important

.active-tab
  font-weight: 700


.verify-message
  font-size: 18px
  line-height: 36px
  color: #999999
  font-weight: 300

.cart-table-content h6, .Cart-cont-shop
  font: 300 16px 'lato', sans-serif
  margin-top: 15px

.cart-total-container
  background: #fe5629

.total-title
  color: #fff
  font-size: 16px
  margin-left: 90px
  margin-top: 12px
  font-weight: 500
  font-family: 'lato', sans-serif

.lyric-txt
  text-align: left
  white-space: break-spaces

  p, strong
    font-family: 'Open Sans', sans-serif
    color: #636363
    font-size: 18px
    line-height: 19px
    font-weight: 300

.license-circle-unavailable
  background-color: rgba(185, 213, 220, .8)

.tooltip-inner
  font-size: 11px
  text-align: left

.rhap_progress-filled
  background-color: #f63 !important

.rhap_progress-indicator
  background-color: #ffffff !important
  border: 3px solid #f63

.my-songs-pagination
  float: right

.my-songs-pagination .page-item a
  background: #ffffff !important
  color: #f63 !important
  border-radius: 0 !important
  margin: 0 8px !important
  border: .5px solid #f63 !important

.my-songs-pagination .page-item.active span
  background: #f63 !important
  color: #ffffff !important
  border-radius: 0 !important
  margin: 0 8px !important
  border: .5px solid #f63 !important

.downloads-dropdown-button .dropdown-menu
  width: auto
  min-height: auto

.exclusive-available-check
  cursor: pointer
  max-width: 160px
  display: inline-block
  width: 100%

.exclusive-available-check:hover .finder, .exclusive-available-check:hover .checker
  opacity: .65

.exclusive-available-check .checker
  background-color: #ffb400
  padding: 0
  color: #fff
  border-color: transparent
  display: flex
  font-family: 'Lato', sans-serif
  align-items: center
  float: left
  width: 70%
  height: 48px
  border-bottom-left-radius: 4px
  border-top-left-radius: 4px
  text-align: center
  font-weight: 600
  line-height: normal

.exclusive-available-check .finder
  background-color: #ffb400
  margin-left: 1px
  width: 29%
  color: #fff
  text-align: center
  height: 48px
  float: right
  border-bottom-right-radius: 4px
  border-top-right-radius: 4px
  font-size: 33px
  padding: 1px

.dropdown-icon
  display: none

.downloads-dropdown-button button::after
  margin-top: 8px !important

.remove-text-content
  display: block

.remove-x-content
  display: none

.order-number
  margin: 40px 0
  font-size: 28px

.order-message
  margin: 40px 0
  border: 4px solid #000000
  font-size: 22px

.exclusive-modal-content
  text-align: center !important
  font-size: 26px

.exclusive-modal-content .title
  color: #292b2c
  font-family: Lato
  font-weight: 400
  line-height: 1.5
  font-size: 33px

.exclusive-modal-arrow
  margin-right: -73px
  position: absolute
  margin-top: 19px
  height: 12px

.exclusive-modal-content p
  color: #a9a9a9
  margin-top: 9px
  text-align: center !important
  font-size: 15px

.exclusive-modal-content .subtitle
  color: darkgray
  margin-bottom: 10px
  font: 300 26px 'lato', sans-serif

.exclusive-modal-content .available-message
  font-weight: 400
  font-size: 26px
  background-color: #f1f4f5
  padding-top: 10px
  color: #ff4000
  font-family: Lato
  font-style: italic
  padding-bottom: 10px

.thanks-container .purchase-message
  font-weight: 700
  background-color: #f1f4f5
  padding-top: 37px
  padding-bottom: 10px
  margin-bottom: 39px
  margin-top: 46px
  line-height: 30px
  justify-content: center

.purchase-message span
  font-size: 26px
  color: rgb(10, 176, 72)
  font-family: Lato
  font-style: italic
  font-weight: 400

.purchase-check-icon
  position: absolute
  color: #0ab048
  top: 24px
  font-size: 40px

.thanks-container
  text-align: center
  font-size: 26px
  height: auto

.link-to-cart
  color: #fff !important
  background-color: #ff4000 !important
  width: 223px
  margin: 15px 0
  height: 58px
  font-weight: 600
  font-family: Lato
  font-size: 16px
  border-color: transparent

.link-to-cart:hover
  opacity: .65

.modal.fade .modal-dialog
  transition: none

.exclusive-modal-body .modal-dialog
  z-index: 1001
  display: flex
  flex-direction: column
  padding: 0
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, .2), 0 24px 38px 3px rgba(0, 0, 0, .14), 0 9px 46px 8px rgba(0, 0, 0, .12)
  background-color: #fff
  background-clip: padding-box
  border: 1px solid rgba(0, 0, 0, .2)
  border-radius: .3rem
  outline: 0
  width: 30%
  min-width: 400px

.exclusive-modal-body .modal-body
  padding: 0

.exclusive-modal-body .type-title
  font-size: 16px
  padding: 12px 0
  text-align: right

.exclusive-modal-body .type-button
  display: inline-flex
  justify-content: center

.exclusive-modal-body .add-cart
  background-color: #ff4000
  color: #fff
  border-top-right-radius: 0
  border-bottom-right-radius: 0
  border-bottom-left-radius: .25rem
  border-top-left-radius: .25rem
  width: 140px
  border: none
  height: 48px
  padding-left: 13px
  padding-top: 8px
  margin-left: 10px

.exclusive-modal-body .price-container
  background-color: #fff
  border-bottom-right-radius: .25rem
  border-top-right-radius: .25rem
  width: 73px
  border-color: #ff4000
  border-style: solid
  padding-top: 9px
  margin-left: -5px
  font-size: 17px
  font-weight: 500
  height: 48px
  text-align: center
  white-space: nowrap
  vertical-align: middle
  display: inline-block

.exclusive-option-type
  margin: 10px 0

.guidline-container
  font-size: 14px
  margin: 14px 0

.modal.show .modal-dialog
  margin: 0
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)

.guidline-container a
  color: #ff4000
  font-style: italic

.eclipsed
  overflow-wrap: break-word

.exclusive-option-type .col-lg-6
  padding: 0

.close-button
  z-index: 10
  position: absolute
  float: right
  font-size: 29px
  right: 10px
  cursor: pointer

.sorry-container
  color: #292b2c

.smaile-img
  height: 40px
  width: auto

.licensed-song-row
  margin: 5px 0

.logout-row
  text-align: center
  position: absolute
  bottom: 15px

.smallOrangebtn
  padding: 7px !important
  margin: 0 !important
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  border-radius: 0.25rem

.downloads-dropdown-button button
  color: #fff !important
  font-size: 14px
  display: flex
  height: 48px
  cursor: default !important
  place-content: center
  padding: 14px
  background-color: #f63 !important
  border: none !important

.downloads-dropdown-button:hover button
  background-color: #ff4000 !important
  padding: 14px !important
  color: #fff !important
  border: none !important

@media screen and (max-width: 1024px)
  .fix-size-list
    min-height: 1688px
  .my-songs-preloader
    margin-top: 1000px

  .exclusive-modal-body .type-title
    text-align: center
  .exclusive-modal-body .modal-dialog
    min-width: 300px
  .license-row
    display: none
  .last-impression .title
    font-size: 40px
  .container p, .container li
    font-size: 18px
    line-height: 49px
  .container .title
    font-size: 30px
    padding: 1em
  .container .subtitle
    font-size: 20px
    line-height: 24px
  .w-container .breadcrumb-item
    font-size: 16px !important
  .hero-title
    font-size: 38px
    line-height: 42px
  .breadcrumb-item
    font-size: 14px
  .shop-filters, .search-container, .shopping-page-content .songs-table
    padding: 0
  .infographic-content h2
    font-size: 30px
    line-height: 34px

@media screen and (max-width: 767px)
  .remove-text-content
    display: none
  .remove-x-content
    display: block
  .totals-container .col-lg-2
    padding: 15px 0
  p, li
    font-size: 16px !important
    line-height: 23px !important
    text-align: justify
  .exclusive-available-check .checker, .dropdown-text
    display: none !important
  .dropdown-icon
    display: block
  .licensed-song-row .col-sm-2
    padding: 0 2px
  .my-songs-table-header .col-sm-2
    padding: 0
  .exclusive-available-check .finder
    width: 100%
    border-radius: 1px
  .about-middle-container .subtitle
    text-align: center
  .hero-title
    display: block
    width: 100%
    overflow-x: hidden
    overflow-y: hidden
    margin-right: auto
    margin-left: auto
    float: none
    font-size: 22px !important
    line-height: 24px
    text-align: center
    letter-spacing: -1px
    text-shadow: rgba(0, 0, 0, 0.70) 0 0 10px
  .pagination-lg .page-link
    padding: 0.4rem 0.6rem

  .centered-content
    margin-top: 50px
  .breadcrumb-container
    display: none
  .logo
    width: 90px !important
    float: left !important
  .brand-logo
    margin-top: 10px
  .scroll-comp
    width: 25%
    font-size: 10px
  .hero-right-col
    height: 40%
    width: 50%
    margin-left: 30%
  .hero-title
    display: block
    width: 100%
    margin-right: auto
    margin-left: auto
    float: none
    font-size: 22px
    line-height: 24px
    text-align: center
    letter-spacing: -1px
  .container .title
    font-size: 21px
    font-weight: 300
    padding: 0.5em
  .scroll-comp img
    width: 70px
  .scroll-comp
    right: 0
  .infographic-content h2
    font-size: 26px !important
    line-height: 32px !important
  .licenses-content .title, .licenses-content .col-lg-12
    padding: 0

@media (max-width: 550px)
  .royalities-earnings .col-lg-4
    padding: 15px 0 !important
  .rhap_progress-section
    margin-left: 0 !important
  .rhap_volume-controls
    display: none
  .reset-button
    display: none
  .scroll-comp img
    display: none
  .scroll-comp
    bottom: 0
    width: 40%
  .filters-row
    display: flex
    padding: 10px 0

@media (max-width: 400px)
  .auth-content-up
    margin-top: -40px
    padding-bottom: 60px
  .sidebar
    width: 320px !important

@media screen and (max-height: 550px)
  .logout-row
    top: 30px
    position: relative

.remove-number
  list-style-type: none

.text-weight
  font-weight: 600


.our-team-page
  .center-name
    display: inline-block
    margin-left: 20px


.applicant-page
  .prof-section-container
    margin-top: 50px
    margin-bottom: 50px

    form
      margin-top: 50px

  .play-button-col
    margin-top: 6px

.checkbox-right-to-submit
  margin-bottom: 30px

.btn-primary
  background-color: #0676ED

.prof-section-container
  form
    .row
      margin-top: 20px

    .form-group
      margin-bottom: 3px

.card-inputs-container
  .billing-row
    display: flex
    gap: 10px
    margin-top: 10px
    &>div
      flex: 1  1  auto
  input
    width: 100%