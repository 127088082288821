
.oval-div
  background-color: white !important

.contact-us-container,.contact-us-container .contact-us-row
  min-height: 800px
  position: relative
  padding: 20px 0
  .background-1
    top: 0 !important
  .background-2
    right: 0
    top: 140px !important
  .contact-us-text
    margin: 60px 0
    text-align: center
    .home-title
      max-width: 400px
    .rocket-logo
      margin-bottom: 60px
  .contact-us-card
    margin: 60px 0
    border-radius: 10px
    padding: 40px
    width: 92%
    height: 540px
    box-shadow: 0 20px 60px 0 rgba(0, 0, 0, 0.2), 0 2px 8px 0 rgba(0, 0, 0, 0.1) !important
    .form-label
      color: #8d8d8d
      font-size: 12px
    .simple-orange-button
      width: 100%
    .form-group
      margin-bottom: 12px
    input.form-control
      height: 60px
    textarea.form-control
      height: 150px
  .home-title
    color: #000000
    font-size: 42px
    font-weight: bold
  .centerable
    display: flex
    align-items: center
    justify-content: center
    flex-wrap: nowrap


@media screen and (max-width: 992px)
  .contact-us-container
    .contact-us-text
      margin: 20px 0 !important
    .container
      padding: 0 !important
    .contact-us-card
      padding: 0 !important
      margin: 20px 0 !important
      width: 100%
      .simple-orange-button
        width: 100% !important
