.shop-filters-section
  background: #ffffff
  position: relative
  border: 1px solid #eeeeee
.filters-body,.profile-modal-body
  .dropdown-toggle, .dropdown-toggle:hover
    width: 100%
    border-color: #cecece
    background: #fff !important
    color: #222b45 !important
    padding: 13px 20px
    text-align: left
    display: flex
    align-items: center
    span
      flex: auto
  .btn-group
    border: 1px solid #cecece
    background-color: #ffffff
    border-radius: 5px
    height: 52px
    .btn
      border-radius: 5px !important
      margin: 7px
      border: none
      padding: 5px 0
.search-row
  margin: 20px 0
  .form-control
    width: 100%
    height: 50px
  .search-button
    font-family: 'Lato', sans-serif
    font-weight: 600
    width: 100%
    height: 50px
    color: #fff
    background-color: #f63
    border-color: transparent
    text-transform: uppercase

.reset-button
  font-family: 'Lato', sans-serif
  font-weight: 600
  height: 53px
  margin-top: 27px
  width: 100%
  color: #ff6633 !important
  background-color: #ffffff !important
  border-color: #ff6633 !important
  display: block
  padding: 1rem 0
  text-transform: uppercase

.oval-div
  height: 66px
  width: 80%
  border-radius: 100%
  background: #fafafa
  position: absolute
  margin-top: 100px
  left: 10%
  @media screen and (max-width: 1100px)
    display: none

.shopping-page-content
  .oval-div
    margin-top: 77px !important
    background-color: #fafafa

.find-button
  font-family: 'Lato', sans-serif
  font-weight: 600
  height: 53px
  margin-top: 27px
  width: 100%
  color: #fff
  background-color: #f63 !important
  background-image: none
  border: none !important
  text-transform: uppercase
