.hero-parallax2
  background: url(../../images/header-bg.jpg) no-repeat center top
  background-size: cover
  position: relative
  text-align: center
  //text-transform: uppercase
  height: 320px
  margin-top: 0
  transition: all .5s
.breadcrumb
  background-color: transparent

.breadcrumb-container
  font-size: 16px
  font-family: Lato, sans-serif
  .breadcrumb
    margin: initial !important

.breadcrumb a
  color: #ffffff
  text-underline: none
.breadcrumb-item
  margin: 0 5px
.breadcrumb-item::before
  content: none !important
.breadcrumb-item a
  transition: all .2s
.login-register-button
  line-height: 55px
  width: 55px
  cursor: pointer
  color: #fff
  text-transform: none
  font-size: 13px
  z-index: 2
#login-legend
  position: absolute
  z-index: 5
  right: 0
  top: 0
.breadcrumb-item:hover a
  color: #ff6633
  text-decoration: none

.w-nav-brand
  position: relative
  float: left
  text-decoration: none
  color: #333333

.navbar
  position: relative
  z-index: 1000
  min-height: 50px
  border: 1px solid transparent
  display: block

.masters img
  margin-top: 5px
  padding-right: 10px
  display: block

.brand-logo .logo
  -webkit-transition: all 150ms ease
  transition: all 150ms ease
  height: 60px

.brand-logo .logo:hover
  -webkit-transform: translate(0px, -3px)
  -ms-transform: translate(0px, -3px)
  transform: translate(0px, -3px)

.w-nav-link
  position: relative
  display: inline-block
  vertical-align: top
  text-decoration: none
  color: #222222
  padding: 20px
  text-align: left
  margin-left: auto
  margin-right: auto

.nav-link
  padding: 45px 10px 10px
  -webkit-transition: all 150ms ease
  transition: all 150ms ease
  color: #f63
  font-family: Arial, sans-serif
  font-size: 16px
  text-transform: uppercase
  text-shadow: black 0px 0px 10px

.logged-in-icon-container,.logged-in-i,
.menu-dropdown-i-c,.menu-dropdown-i
  height: 35px
  width: 35px
  display: block
  cursor: pointer
  border-radius: 100%
.logged-in-i
  background: url("../../icons/user.svg") no-repeat center
.menu-dropdown-i
  mask: url("../../icons/stem.svg") no-repeat center
  background-color: #545e79
  transform: rotate(90deg)
.menu-dropdown-i-c,.logged-in-icon-container
  margin: 0 5px
  background-color: #ffffff
.hero-content.hero-content-container.v2 .container,.hero-content.hero-content-container.v2 .title
  padding: 0
.hero-content.hero-content-container.v2 .container
  margin-top: 50px
  @media screen and (max-width: 600px)
    margin-top: 0px
.hero-parallax2 .hero-content img
  max-height: 70px
.hero-parallax2
  text-align: center

.hero-parallax2 .title
  font-family: 'lato', sans-serif
  font-weight: 100
  text-transform: none
  font-size: 48px
  color: #fff

.user-icon
  height: 24px
  width: 24px
  background: url(../../images/LOGIN.png) no-repeat center center

.resposive-icons
  display: none
////dropdown
.rs-burger:before
  content: "o"
.dropdown-content
  position: fixed
  top: -196.23px
  left: 0
  width: 100%
  background-color: rgba(0,0,0,0.85)
  transition: all .5s
.dropdown-content.visible
  top: 0
  z-index: 5
.dropdown-buttons
  font-size: 18px
  color: #f63
  text-transform: uppercase
  text-shadow: #000 0 0 10px
  transition: all .15s ease
  background-color: transparent
  border: none
  width: 100%
  text-align: left

.dropdown-buttons:hover
  background-color: #f63
  color: #ffffff
.dropdown-buttons.active
  background-color: #f63 !important
  color: #ffffff !important
.dropdown-content a
  color: #ffffff
.dropdown-content a:active
  color: red
.resposive-dropdown
  z-index: 5
  position: absolute
  right: 2%
  top: 25%
.dropdown-content .btn-primary:not(:disabled):not(.disabled):active
  background-color: #f63 !important
  border-color: #f63 !important
.header-subtitle
  text-transform: uppercase
  margin-bottom: .5rem
  font: 500 14px 'arimo', sans-serif
  line-height: 1.1
  color: #ffffff
.hero-content-container
  position: relative
.trackowner-subtitle
  color: #ababab !important
@media (max-width: 991px)
  .logo
    width: 70%

@media screen and (max-width: 767px)
  .hero-parallax2
    height: auto
    padding-bottom: 60px
  .brand-logo
    margin-top: 5px

  .resposive-icons
    display: flex
  .w-container .col-lg-6
    display: flex
    align-items: center
    justify-content: center
  .breadcrumb-container
    display: none
  .logo
    width: 90%
  .login-register-button
    display: none
  .hero-content-container .title
    font-size: 30px !important

@media screen and (max-width: 576px)
  .logo
    width: 110%
  .hero-content-container .title
    font-size: 30px !important
