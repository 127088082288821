.cart-container
  .content-section
    min-height: 520px
  .oval-div
    background-color: #ffffff !important
  .cart-title-col
    flex: 0 1 17%
    @media screen and (max-width: 566px)
      flex: 1 1 auto
  .license-dropdown-row
    flex-wrap: wrap
    margin: 0 5px
    flex: 0 1 20%
    justify-content: center
    display: flex
    @media screen and (max-width: 1168px)
      display: flex
      justify-content: center
      flex: 0 1 50%
      margin: 10px auto
      &.song
        order: 6
      &.exclusivity
        order: 7
    @media screen and (max-width: 566px)
      flex: 0 1 100%
      border-top: 1px solid #eeeeee
      margin: 0
      padding: 10px 0
      &.song
        order: 4
      &.exclusivity
        order: 5
  .play-button-col
    border: none
    @media screen and (max-width: 600px)
      height: 125px
    @media screen and (max-width: 566px)
      flex: 0 1 21%

  .checked-item
    border: 1px solid rgba(29, 112, 225, 0.5)
  .error-cart-item
    border: 1px solid rgba(255, 0, 0,.5)
  .card
    background-color: #fafafa
  .month-div
    font-family: Roboto
    font-weight: 400
    font-size: 12px
    color: #222b45
  .Cart-table-header-container
    border: none
    background: transparent
  .Cart-table-header
    display: flex
    border-bottom: 1px solid #e1e1e1
    overflow: hidden
    padding-bottom: 10px
    margin: 0 15px
    text-align: center
    font-family: Roboto, sans-serif
    font-size: 18px
    color: #8e96ab
    font-weight: 500
    @media screen and (max-width: 1168px)
      display: none
    .details-col
      flex: 0 1 24%
    .license-col
      flex: 0 1 20%
      min-width: 280px
      margin: 0 5px

      @media screen and (max-width: 1200px)
        flex: 0 1 24%
      @media screen and (max-width: 1100px)
        flex: 0 1 26%
    .price-col
      flex: 0 1 8%
    span
      font: normal 15px 'arimo', sans-serif
      color: #6d6d6e
  .single-cart-row-container
    display: flex
    margin: 15px
    border-radius: 0.25rem
    flex-wrap: nowrap
    align-items: center
    @media screen and (max-width: 566px)
      margin: 5px
      .card-body
        padding: 0
  .cart-process-checkbox
    flex: 1 1 auto
    transform: scale(.7)
    padding: 20px
    @media screen and (max-width: 650px)
      padding: 12px

  .single-song-card
    flex: 1 1 100%
  .checkbox-lg .custom-control-label::before,
  .checkbox-lg .custom-control-label::after
    width: 1.85rem
    height: 1.85rem
  .checkbox-lg .custom-control-label
    padding-top: 13px
    padding-left: 6px
  .hidden-titles
    flex: 0 1 100%
    font-family: Roboto, sans-serif
    font-size: 15px
    color: #8e96ab
    font-weight: 500
    text-align: center
    display: none
    @media screen and (max-width: 1168px)
      display: block

  .song-select-drop,.exclusivity-select-drop
    width: 280px
    .dropdown-toggle,.dropdown-item

      min-height: 57px
      display: flex
      align-items: center
      flex-wrap: wrap
      color: #8798ad !important
      border-color: #dbe1eb
      padding: 10px
      .drop-item-container
        display: flex
        flex-wrap: wrap
        align-items: center
        color: #222b45
        .exclusive-m-container,.exclusive-icon, .exclusive-icon-small
          height: 35px
          width: 35px
          display: block
          cursor: pointer
          border-radius: 100%
          margin-right: 10px !important
        .exclusive-icon
          mask: url("../../../icons/exclusive.svg") no-repeat center
          background-color: #ffffff
        .exclusive-icon-small
          mask-size: 18px
        .exclusive-m-container
          margin: 0 10px
          background: linear-gradient(90deg, rgba(255,102,51,1) 0%, rgba(235,76,93,1) 100%)
      .song-comp-m-container,.stems-mask-container,.exclusive-m-container
        margin-right: 10px !important
        &,.song-comp-i,.stems-icon,.exclusive-icon
          height: 35px
          width: 35px
      .drop-text-container
        font-size: 18px
        text-align: left
        font-family: Roboto, sans-serif
        flex: 1 1 auto
        .price-container
          font-family: Roboto, sans-serif
          font-weight: bold
          font-size: 23px !important
  .exclusivity-select-drop
    .drop-text-container
      font-family: Lato, sans-serif
      font-weight: bold
      font-size: 25px !important
    .drop-text-container.small-font-drop
      font-size: 20px !important
      font-weight: 400

  .price-row
    flex: 0 1 8%
    font-size: 26px
    font-family: Lato, sans-serif
    font-weight: bold
    color: #8798ad
    text-align: center
    &.clean-border
      span
        opacity: 0 !important
    @media screen and (max-width: 1168px)
      flex: 0 1 20%
    @media screen and (max-width: 710px)
      flex: 0 1 15%
    @media screen and (max-width: 566px)
      flex: 0 1 50%
      order: 6
      border: 1px solid #eee

  .delete-col
    flex: 1 1 auto
    @media screen and (max-width: 566px)
      flex: 0 1 20%
  .delete-mask-container,.delete-icon
    height: 46px
    width: 46px
    display: block
    margin-left: auto
    cursor: pointer
    margin-right: auto
    border-radius: 100%
  .delete-mask-container
    background-color: #ffffff
    border: 1px solid #ffdad8
  .delete-icon
    mask: url("../../../icons/trash-alt-solid.svg") no-repeat center
    background-color: #e74242
    border: 1px solid #d0d3dc
    mask-size: 17px
  .fixed-cart-details-container
    height: 140px
    display: flex
    flex-wrap: wrap
    width: 100%

    background-color: #202530
    z-index: 1
    align-items: center
    color: #ffffff
    @media screen and (max-width: 900px)
      height: auto
    .error-coupon
      color: red
      font-size: 16px
    .continue-shop-container
      flex: 0 1 15%
      text-align: center
      font-size: 18px
      font-family: Lato, sans-serif
      font-weight: bold
      text-decoration: underline
      @media screen and (max-width: 900px)
        flex: 0 1 100%
        margin: 30px 0
      a
        color: #ffffff !important
    .coupon-container
      border-right: 1px solid #e4ebf2
      padding-left: 20px
      display: flex
      flex: 1 1 auto
      height: 55px
      align-items: flex-end
      &.coupon-code-activated
        align-items: center
      @media screen and (max-width: 900px)
        height: auto
        border-right: none
        padding: 20px 0
        flex: 0 1 100%
      .coupon-input
        flex: 0 1 80%
        height: auto
        margin: 0 10px
        display: flex
        align-items: flex-end
        @media screen and (max-width: 900px)
          margin: 0 7px
          width: 100%
          height: 100%
        .coupon-code
          font-size: 16px
          span
            font-size: 24px
            color: #0ab048
            margin-left: 15px
          @media screen and (max-width: 460px)
            font-size: 12px
            span
              font-size: 16px
              word-break: break-all
              margin-left: 0px




      .profile-input-labels
        flex: 1 1 auto
      .form-group
        margin-bottom: 0
      .form-control
        height: 55px
      .coupon-add-button
        flex: 1 1 20%
        display: flex
        justify-content: center
        @media screen and (max-width: 900px)
          margin-top: 27px
          &.coupon-remove-button
            margin-top: 0
        button
          width: 55px
          height: 55px
          background-color: #00ac5a !important
          color: #fff
          border-radius: 5px
          border: none !important
      .coupon-remove-button
        button
          background-color: red !important
    .discount-price div
      color: #ff2828
    .price
      flex: 0 1 11%
      text-align: center
      @media screen and (max-width: 900px)
        flex: 0 1 50%
        padding: 20px 0
        &.total-pay
          div
            font-family: Roboto, sans-serif
            font-weight: bold
            font-size: 40px
      .scratched
        text-decoration: line-through
      p,div
        font-family: Roboto, sans-serif
        font-weight: bold
      p
        text-align: center !important
        font-size: 14px
        margin-bottom: 5px
      div
        font-size: 24px
    @media screen and (max-width: 900px)
      .discount-price,.no-price
        border-bottom: 1px solid #e4ebf2
        border-top: 1px solid #e4ebf2
        div
          font-size: 33px !important
          font-family: Roboto, sans-serif
          font-weight: bold
    .checkout-button
      flex: 0 1 14%
      height: 55px
      padding: 0 20px
      border-right: 1px solid #e4ebf2
      @media screen and (max-width: 1220px)
        flex: 1 1
        height: 92px
        display: flex
      @media screen and (max-width: 900px)
        padding: 0 10px
        flex: 0 1 50%
        border-right: none
      button
        height: 100%
        max-height: 62px
        margin: 0
        padding: 0
        align-self: center


