
.profile-page
  .password-reset-section .profile-update-btn
      margin-top: 24px
  .secondary-desc-text
    color: #6d7783
  .profile-services
    padding: 50px 15px
    .find-button
      height: 55px
      width: 85%
      margin-top: 0
    img
      height: 65px

  .my-profile-image
    max-height: 100%
    align-self: center
    max-width: 100%
  .centered-upload-button-container
    z-index: 10
    border: none
    position: absolute
    top: 38%
    width: 100%
    text-align: center
    color: white
  .centered-upload-button
    z-index: 10
    border: none
    position: absolute
    top: 38%
    left: 50%
    text-align: center
    color: white
  .upload-button-container
    position: absolute
    opacity: 0
    height: 258px
    width: 258px
    transition: all .2s
    background-color: #ff663347
  .upload-button-container:hover
    opacity: 1
  .upload-pic-container
    height: 258px
    width: 258px
    display: flex
    border: 1px solid  #f63
    .spinner-border
      color: #b9c136
      z-index: 10
      position: absolute
      height: 4rem
      width: 4rem
      left: 45%
      margin-left: -21px
      top: 44%
      margin-top: -19px
  .no-padding-right
    padding-right: 0 !important
  .my-prof-row
    .col-lg-3, .col-lg-6, .col-lg-4, .col-lg-12,.col-lg-9
      padding-left: 0
    .custom-file
      margin-top: 28px
      .custom-file-label
        color: black
        border-color: #f63
      .custom-file-label::after
        background-color: #f63
        color: #ffffff
  .my-prof
    color: #444444
    margin: 1rem 0
    font-weight: 400
    font-family: 'Lato', sans-serif
  .profile-header-links
    color: #8e96ab !important
    text-transform: uppercase
    font-weight: 700

.warn-checkbox
  color: red
  animation: scaling .7s

.spinner-with-loading
  display: flex
  align-items: center
  justify-content: center
  span
    margin-left: 10px

.oval-div
  background-color: #f1f4f5

.profile-header-menus
  background-color: #f1f4f5
  padding: 1rem 0
  text-align: center
