.hiw-page
  font-family: Lato
  .hidden-title
    display: none
  .hiddenresp
    display: none
  .disks-container
    display: flex
    flex-wrap: nowrap
    overflow: auto
    left: 0
    right: 0
    margin: 20px auto
    justify-content: center
    .single-disk
      position: relative
      height: 141px
      width: 141px
      margin: 0 10px
      .disk-image
        height: 100%
        width: auto
        position: absolute
        z-index: 2
        background-position: center
        background-size: cover
  .container
    max-width: 1300px
  .wait-container
    padding: 80px 0
    position: relative
    @media screen and (max-width: 992px)
      padding-bottom: 20px
    .wait-button
      text-align: center
      margin-bottom: 80px
      button
        width: 274px
    .wait-subtitle
      text-align: center
      font-size: 22px
      color: #222a41
      margin-top: 30px
      margin-bottom: 60px
    .wait-title
      margin: 80px
      font-size: 30px
      color: #172230
      text-align: center
    .waiting-card
      position: relative
      display: flex
      background-image: url("../../../images/so_what_are_you_waiting_for.jpg")
      background-size: cover
      background-position: center
      height: 300px
      width: 100%
      border-radius: 15px
      padding: 100px
      align-items: center
      justify-content: center
      .simple-orange-button
        z-index: 2
      .waiting-layout
        position: absolute
        height: 100%
        width: 100%
        border-radius: 15px
        background-color: rgba(8, 21, 34, 0.4)
      .wait-text
        font-size: 30px
        color: #ffffff
        margin: auto 60px
        z-index: 2
      .icon-container
        height: 88px
        width: 88px
        background: #ffffff
        border-radius: 10px
        z-index: 2
        .contain-icon
          height: 100%
          width: 100%
          mask: url("../../../icons/graph.png") no-repeat center
          background-color: #ff6633
          mask-size: 60px
  .royalties-section
    position: relative
    background-color: #eff3f8
    .row
      padding: 70px 0
    .roy-image-container
      position: relative
      height: 550px
      width: 100%
      .roy-image-background
        background-color: #ff5529
        width: 500px
        height: 550px
        position: absolute
        top: 11px
        left: -5px
      .roy-image
        width: 500px
        height: 550px
        position: absolute
        background-image: url("../../../images/royalties.jpg")
        background-position: center
        background-size: cover
        left: 0
    .royalties-text-container,.title-container
      margin-top: 170px
      margin-bottom: 70px
      @media screen and (max-width: 992px)
        margin-top: 60px
      .orange-par-title
        padding-left: 30px
        color: #ff5529
        font-size: 16px
        font-weight: bold
      .allow-title
        padding-left: 30px
        font-size: 52px
        font-weight: bold
        color: #000000
      .allow-desc
        padding-left: 30px
        margin-bottom: 50px
        margin-top: 30px
        font-size: 22px
        color: #333333
        max-width: 430px
        border-left: 2px solid #ff5529
      .allow-desc-child
        padding-left: 30px
        color: #333333
        font-size: 22px
        max-width: 430px
  .sec-title
    text-align: center
    font-size: 30px
    margin-bottom: 100px
  .license-allowing
    background-image: radial-gradient(circle at 68% 40%, #0b2233, #102943 44%), linear-gradient(to bottom, #091726, #091726)
    padding: 80px 0
    .orange-par-title
      padding-left: 30px
      color: #ff5529
      font-size: 16px
      font-weight: bold
    .allow-title
      padding-left: 30px
      font-size: 52px
      font-weight: bold
      color: #ffffff
    .allow-desc
      padding-left: 30px
      margin-bottom: 80px
      margin-top: 30px
      font-size: 22px
      color: #ffffff
      max-width: 430px
      border-left: 2px solid #ff5529
    .allow-desc-child
      padding-left: 30px
      color: #bababa
      font-size: 22px
      max-width: 430px
    .license-allow-img-container
      height: 500px
      width: 100%
      position: relative
      .big-background
        position: absolute
        height: 700px
        width: 90%
        background-image: url("../../../images/license_allows_you.jpg")
        background-position: center
        background-size: cover
        max-width: 800px
        left: 0
        z-index: 50

  .with-song
    position: relative
    padding: 80px 0 !important

    .second-ws
      .item-card
        margin-top: 60px
    .item-card
      width: 100%
      min-height: 150px
      padding: 10px 0
      display: flex
      border: 1px solid #eeeeee
      align-items: center
      border-radius: 20px
      box-shadow: 0 12px 24px 0 rgba(0, 0, 0, 0.02) !important
      .item-icon-wt
        display: flex

      .item-text
        font-size: 20px
        color: #222a41
        span
          font-weight: bold
      .item-ic-container
        height: 70px
        width: 70px
        background-color: #d9e9fa
        border-radius: 100%
        margin: 40px
        flex: 1 0 auto

        .song-icon,.stem-icon,.song-track-icon,.exclusive-icon
          height: 100%
          width: 100%
          background-color: #0676ed
          mask-size: 23px
        .song-icon
          mask: url("../../../icons/song_only.svg") no-repeat center
        .stem-icon
          mask: url("../../../icons/stem.svg") no-repeat center
        .song-track-icon
          mask: url("../../../icons/song_track.svg") no-repeat center
        .exclusive-icon
          mask: url("../../../icons/exclusive.svg") no-repeat center
          background-color: #ffffff
      .item-ic-container.exclusive
        background-image: linear-gradient(50deg, #eb4c5d 9%, rgba(228, 48, 93, 0.9) 22%, #ff6633 88%), linear-gradient(to bottom, #dc7252, #dc7252)
    .item-card.second
      margin-left: 25px
      margin-top: 80px
  .find-song-section
    background-image: radial-gradient(circle at 68% 40%, #0b2233, #102943 46%)
    padding: 80px 0 50px 0
    .find-s-b-cont
      text-align: center
      margin: 30px auto
    .blue-title
      font-size: 40px
      color: #429af8
      line-height: 44px
      text-align: center
      max-width: 654px
      margin: 30px auto
    .content-text
      color: #ffffff
      font-size: 22px
      text-align: center
      max-width: 940px
      margin: auto
    .song-icon-borders
      height: 93px
      width: 93px
      border-radius: 30%
      border: 1px solid rgba(6, 118, 237, 0.3)
      margin: auto
      .song-icon-container
        background-color: #e4f0fc
        height: 100%
        width: 100%
        border-radius: 100%
        box-shadow: 0 0 25px 5px #0676ed !important
        .song-icon
          height: 100%
          width: 100%
          background-color: #0676ed
          mask: url("../../../icons/song_only.svg") no-repeat center
          mask-size: 23px
  .artist-section
    position: relative
    padding: 80px 0
    .art-right-text
      padding-left: 20px
      font-size: 46px
      font-weight: bold
      color: #333333
      border-left: 3px solid #ff5529
    .images-sec
      position: relative
      margin: 40px 0
      .image-sec-container
        position: relative
        min-height: 440px
        width: 100%
        .yellow-div
          position: absolute
          z-index: 1
          width: 310px
          height: 310px
          background-color: #ff5529
          bottom: 0
          left: 0
          right: 0
          margin: auto
        .image-back.guitarist
          position: absolute
          height: 400px
          width: 310px
          background-image: url("../../../images/are_you_an_artist_1.jpg")
          background-size: cover
          z-index: 3
          left: 0
          bottom: 40px
        .image-back.photogit
          position: absolute
          height: 253px
          width: 296px
          background-image: url("../../../images/are_you_an_artist.jpg")
          background-size: cover
          z-index: 2
          bottom: -40px
          right: 90px
  .hero-parallax2
    background-image: url(../../../images/hiwBack.jpg)  !important
    height: 700px
    max-height: initial
    text-transform: none
    .header-title-child
      margin-top: 160px
      font-size: 52px
      font-weight: bold
      text-align: left
      color: #ffffff
      span
        color: #ff5529
    .header-subtitle-child
      margin-top: 21px
      width: 70%
      font-family: Roboto
      font-weight: 300
      font-size: 38px
      color: #92a5c3
      text-align: left
@media screen and (max-width: 992px)
  .hiw-page
    .royalties-section
      .roy-image-container
        height: 550px !important
        width: 100%
        .roy-image
          width: 540px !important
          height: 590px !important
          right: 0 !important
        .roy-image-background
          width: 540px !important
          height: 590px !important
          right: 10px !important
          left: initial !important
      .row
        padding: 0
      .col-lg-6
        display: flex
        flex-wrap: wrap
    .title-container
      display: none !important
    .title-container.hiddenresp
      display: block !important
      margin-top: 30px
      margin-bottom: 30px
      margin-left: 30px
      width: 100%
    .license-allowing
      .col-lg-6
        display: flex
        flex-wrap: wrap
        .allow-desc,.allow-desc-child
          max-width: 500px
    .license-allow-img-container
      .big-background
        height: 100% !important
        width: 100% !important
        left: -15px !important
        right: initial !important
        background-position: right !important
    .art-right-text
      font-size: 36px !important
      margin: 40px auto
    .with-song .row .col-lg-6
      display: flex
      flex-wrap: wrap
      justify-content: center
    .waiting-card
      flex-wrap: wrap
      padding: 20px !important
      .icon-container
        flex: 0 1 auto
      .wait-text
        margin: 20px 0 !important
        text-align: center
        flex: 0 1 100%
      .simple-orange-button
        flex: 0 1 auto
    .item-card.second,.second-ws .item-card
      margin-top: 20px !important
    .images-sec
      display: flex
      justify-content: center
      .image-sec-container
        width: 560px !important
    .hero-parallax2
      height: auto
      .header-title-child
        width: 80% !important
        font-size: 48px !important
        padding-left: 20px
      .header-subtitle-child
        width: 80% !important
        font-size: 33px !important
        padding-left: 20px

@media screen and (max-width: 556px)
  .hiw-page
    .hero-parallax2
      background-image: url(../../../images/hiwBackMobile.jpg)  !important
    .royalties-section
      .roy-image-container
        height: 360px !important
        width: 100%
        .roy-image
          width: 320px !important
          height: 365px !important
          right: 0 !important
          left: initial !important
        .roy-image-background
          width: 320px !important
          height: 365px !important
          right: 10px !important
          left: initial !important
    .item-card
      display: flex
      flex-wrap: wrap
      align-items: center
      padding: 20px !important
      span
        display: none
      .item-icon-wt
        display: flex
        flex-wrap: nowrap
        align-items: center
        .item-ic-container
          margin: 15px
        .hidden-title
          font-weight: bold
          font-size: 20px
          display: block
    .allow-title,.orange-par-title
      padding: 0 !important
    .license-allowing
      .allow-title
        font-size: 40px !important
      .license-allow-img-container
        width: 90%
        margin-right: 10%
    .find-song-section
      padding: 50px 20px
      .find-s-b-cont
        margin: 30px auto
      .blue-title
        font-size: 22px !important
    .art-right-text
      font-size: 22px !important
    .images-sec
      .image-sec-container
        width: 320px !important
        min-height: 190px !important
        .yellow-div
          height: 182px !important
          width: 182px !important
          bottom: 20px !important

        .image-back.guitarist
          left: 30px !important
          width: 183px !important
          height: 236px !important

        .image-back.photogit
          width: 174px !important
          height: 149px !important
          bottom: 0 !important
          right: initial !important
          left: 110px !important

    .hero-parallax2
      height: auto
      .header-title-child
        font-size: 44px !important
        width: 90% !important
      .header-subtitle-child
        width: 90% !important
        font-size: 29px !important
