
.subscription-modal
  .modal-dialog
    max-width: 1150px
    width: 100%
    max-height: 80%
    img
      height: auto !important
    @media screen and (max-width: 1440px)
      max-width: 750px
      img
        height: 60px !important
      .title-container
        .big-title
          font-size: 26px !important
        .small-title
          margin-top: 1rem !important
          font-size: 18px !important
      form
        margin-top: 1rem !important
        .form-check-label
          font-size: 16px !important
        .form-check-input
          transform: scale(1.5) !important
      button
        margin-left: 40px !important
        padding: 0 25px !important
        font-size: 20px !important
  .subscription-modal-body
    background-color: #ed6031
    .subscription-form
      form
        margin-top: 3rem
        display: flex
        justify-content: center
        flex-wrap: wrap
        .form-check
          margin: 2rem 0
          flex: 0 1 80%
          display: flex
          align-items: center
          .form-check-input
            margin-top: 0
            transform: scale(2)
          .form-check-label
            color: #ffffff
            font-size: 20px
            margin-left: 10px
        .form-group
          flex: 0 1 60%
          margin-bottom: 0
          input
            height: 100%
            border-radius: 0
            border: none
            background-color: #fbd7cc
        button
          background-color: #d72d01
          border: none
          color: #ffffff
          font-weight: bold
          flex: 0 1 auto
          font-size: 30px
          margin-left: 60px
          padding: 5px 50px
    .logo-container
      text-align: center
      margin-bottom: 1rem
    .title-container
      color: #ffffff
      text-align: center
      .big-title
        font-size: 40px
        font-weight: bold
      .small-title
        margin-top: 3rem
        font-size: 26px

  @media screen and (max-width: 880px)
    .modal-dialog
      max-width: 1200px
      width: 95%
      .subscription-modal-body
        background-color: #ee6031
        .subscription-form
          form
            margin-top: 3rem
            display: flex
            justify-content: center
            flex-wrap: wrap
            .form-check
              margin: 2rem 0
              flex: 0 1 80%
              display: flex
              align-items: baseline
              @media screen and (max-width: 500px)
                margin: 1.2rem 0 !important
              .form-check-input
                margin-top: 5px
                transform: scale(2)
              .form-check-label
                color: #ffffff
                font-size: 16px
                margin-left: 10px
            .form-group
              flex: 0 1 100%
              height: 45px
              margin-bottom: 0
              input
                height: 100%
                border-radius: 0
                border: none
                background-color: #fbd7cc
            button
              margin-top: 2rem
              background-color: #d72d01
              border: none
              color: #ffffff
              font-weight: bold
              flex: 0 1 auto
              font-size: 30px !important
              margin-left: 0
              padding: 0 30px
              @media screen and (max-width: 500px)
                margin-top: 1.5rem !important
        .logo-container
          text-align: center
        .title-container
          color: #ffffff
          text-align: center
          .big-title
            font-size: 26px
            font-weight: bold
            @media screen and (max-width: 500px)
              font-size: 22px !important
          .small-title
            margin-top: 0rem
            font-size: 18px

